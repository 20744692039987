import { Injectable } from '@angular/core';
import { TyWidget } from '../models/ty-widget.model';
import { TyDriver } from '../models/ty-driver.model';
import { Observable,of } from 'rxjs';
import { SearchService } from './search.service';


@Injectable()
export class DriverService {
  private _drivers:TyDriver[];
  public lsDrivers:TyDriver[] = JSON.parse(localStorage.getItem('drivers')) || require('../../../../../assets/_config/data/driver.json');// {};
/*   counter = widgetCounter;
  counterOptions = widgetOptions;
  counterPie = widgetPie;
  counterOptionsBarchart = widgetOptionsBarChart;
  counterOptionsDoubleBarchart = widgetOptionsDoubleBarChart; */

  constructor(public search: SearchService) {



    this._drivers = require('../../../../../assets/_config/data/driver.json');


  }
  setDriver(driver) {
    let d:TyDriver;
    for(let i=0; i<this.lsDrivers.length;i++) {
      if (this.lsDrivers[i]._id === driver._id) {
        this.lsDrivers[i] = driver;
        break;
      }
    }
    console.log(driver);
    localStorage.setItem('drivers', JSON.stringify(this.lsDrivers));
  }
  public mockData(amount) {
    return generateMockDrivers(amount);
  }
  public uploadSearchIndex(drivers) {
    let driversIndex: any = drivers.map((driver)=> {
      delete driver.center_of_life;
      delete driver.dashboards;
      delete driver.contract;
      return driver;
    });

    console.log('driversIndex',driversIndex);

    this.search.pushDrivers(driversIndex);
  }

  getAll(): Observable<TyDriver[]> {
    return of(this.lsDrivers);// _drivers);
  }
  getOne(id): Observable<TyDriver> {
    let driver:TyDriver;
    // this._drivers.forEach((v) => {
    this.lsDrivers.forEach((v) => {
      console.log(v._id);
      if (v._id === id) {
        driver = v;
      }
    });
    console.log(id);
    console.log(driver);

    return of(driver);
  }

}
export const widgetIncPerMo:TyWidget = {
  icon: 'ty_icon ty_widgets incidents-per-month',
  title: '# Incidents per month',
  subtitle: 'total',
  _gridsize: 4,
  value: '3',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow:true
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetDrivingScore:TyWidget = {
  icon: 'ty_icon ty_widgets driving-score',
  title: 'Driving Score',
  subtitle: 'May 2019',
  _gridsize: 8,
  value: '88',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow: true
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetAvgCo2:TyWidget = {
  icon: 'ty_icon ty_widgets avg-co-2',
  title: 'Avg Co2 g/km',
  subtitle: 'total',
  _gridsize: 4,
  value: '44',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow:true
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetFuelScore:TyWidget = {
  icon: 'ty_icon ty_widgets fuel-score',
  title: 'Fuel Efficiency Score',
  subtitle: 'total',
  _gridsize: 4,
  value: '88',
  _type: {
    counterOnly: true,
    counterShow: true
  },
};
export const widgetIncProfDrv:TyWidget = {
  icon: 'ty_icon ty_widgets  incidents-per-month-copy',
  title: 'Incidents Profile',
  subtitle: 'total',
  _gridsize: 4,
  value: '13',
  _type: {
    primary_chart:'pie',
    counterShow: false
  },
  chart_data: {
    'one_month': {
      primary: generateMockSingle()
    },
    'one_year': {
      primary: generateMockSingle()
    },
    'three_year': {
      primary: generateMockSingle()
    }
  }
};
export const widgetInc:TyWidget = {
  icon: 'ty_icon ty_widgets incidents',
  title: '# Incidents',
  subtitle: 'total',
  _gridsize: 6,
  value: '4',
  _type: {
    primary_chart:'grouped-barchart'
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetPersonalCorporateDrv:TyWidget = {
  icon: 'ty_icon ty_widgets graph',
  title: 'Personal Vs Corporate Score',
  subtitle: '',
  _gridsize: 4  ,
  value: '',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow: false
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetPersonalCorporate:TyWidget = {
  icon: 'ty_icon ty_widgets graph',
  title: 'Personal Vs Corporate Score',
  subtitle: '',
  _gridsize: 4,
  value: '',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow: false
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetIncProf:TyWidget = {
  icon: 'ty_icon ty_widgets incidents-per-month-copy',
  title: 'Incidents Profile',
  subtitle: 'total',
  _gridsize: 6,
  value: '13',
  _type: {
    primary_chart:'pie',
    counterShow: false
  },
  chart_data: {
    'one_month': {
      primary: generateMockSingle()
    },
    'one_year': {
      primary: generateMockSingle()
    },
    'three_year': {
      primary: generateMockSingle()
    }
  }
};
export const widgetFuel:TyWidget = {
  icon: 'ty_icon ty_widgets fuel',
  title: 'Fuel Efficiancy',
  subtitle: 'km / litre',
  _gridsize: 4,
  value: '20',
  _type: {
    primary_chart:'grouped-barchart',
    counterShow: true
  },
  chart_data: {
    'one_month': {
      primary: generateMockSeries()
    },
    'one_year': {
      primary: generateMockSeries()
    },
    'three_year': {
      primary: generateMockSeries()
    }
  }
};
export const widgetOdemeter:TyWidget = {
  _gridsize: 6,
  icon: 'ty_icon ty_widgets total-mileage',
  title: 'Odemeter',
  subtitle: 'Mileage in km',
  value: '103,212',
  _type: {
    counterOnly: true,
    counterShow: true
  }
};
export const widgetService:TyWidget = {
  _gridsize: 6,
  icon: 'ty_icon ty_widgets spanner',
  title: 'Service Inspection',
  subtitle: 'Next Service Due',
  value:'',
  _type: {
    optionsOnly:true,
    infoShow: true,
    counterShow:true
  },
  info_title:'Chosen service center',
  info_subtitle:'AB Afula LTD.',
  options: [
    {
      title: 'Previous',
      _selector: 'prev'
    },
    {
      title: 'Next',
      _selector: 'next'
    }
  ],
  values: {
    'prev': {
      value:'88 days',
      subtitle:'Previous',
      info_subtitle:'Previous',
      info_title:'Previous'
    },
    'next':{
      value:'22 days',
      subtitle:'Next',
      info_subtitle:'Next',
      info_title:'Next'
    }
  }
};
export const contract = [
  {
    'clause': 'Organization',
    'information': 'Elise',
    'status': '',
    'actions': [
      {
        label:'Action1',
        class:'brand'
      },
      {
        label:'Action2',
        class:'success'
      },
      {
        label:'Action3',
        class:'warning'
      }
    ]
  },
  {
    'clause': 'Mileage Allowence',
    'information': '4,000 km',
    'status': {
      label:'+35%',
      class:'danger'
    },
    'actions': [
      {
        label:'Action1',
        class:'brand'
      },
      {
        label:'Action2',
        class:'success'
      },
      {
        label:'Action3',
        class:'warning'
      }
    ]
  },
  {
    'clause': 'Geo restrictions',
    'information': 'Israel Only',
    'status': {
      label:'OK',
      class:'success'
    },
    'actions': [
      {
        label:'Action1',
        class:'brand'
      },
      {
        label:'Action2',
        class:'success'
      },
      {
        label:'Action3',
        class:'warning'
      }
    ]
  },
  {
    'clause': 'Duration',
    'information': '4 Years',
    'status': '',
    'actions': [
      {
        label:'Action1',
        class:'brand'
      },
      {
        label:'Action2',
        class:'success'
      },
      {
        label:'Action3',
        class:'warning'
      }
    ]
  },
];
/* =========================== mocking =============================== */
export function mockNames() {
  return [
    'Hayden Moss','Elise Forester','Kamila Brown','Josh Gardner','Anabelle Whatson','Cameron Hastings','Callie Tanner',
    'Kaylee Myatt','David Garner','Moira Saunders','Sebastian Campbell','Rick Huggins','Tiffany Ross','Jayden Gordon','Nicholas Rowe',
    'Rick Dixon','Angel Carter','Cara Archer','Daron Middleton','Gemma Mcgregor','Hope Sherry','Nancy Griffiths','Paige Shelton','Mason Powell','Ryan Mcguire',
    'Janelle Plant','Ruth Vaughan','Anabel Richards','Mya Anderson','Angela Ellis','Barry Wren','Hank Wilcox','Luke Murray','Francesca Nayler','Gwenyth Leslie',
    'Regina Olson','Carter Wigley','Judith Willis','Alma Khan','Zara Snow','Adalind Gavin','Melania Brown','Maxwell Penn','Melinda Evans','Luna Ralph',
    'Barney Blythe','Noah Barclay','Johnny Duvall','Julius Ashley','Sarah Irwin','Rick Saunders',
    'Phillip Farrow','Ronald Everett','Elijah Hunt','Martin Ellwood','Wade Lewis','Adina Ellis','Hannah Jenkin','Makenzie Mackenzie','Danny Newton','Juliet Hunt',
    'Destiny Rose','Matthew Sheldon','Nina Glynn','Eve Rose','Daron Shields','Dani Thomas','Zara Heaton','Teagan Reese','Ryan Rossi','Eden Redfern','Aiden Watt',
    'Oliver Rowlands','Brad Emerson','Shelby Vangness','Javier Glynn','Julian Kennedy',
    'Mason Antcliff','Kieth Ross','Percy Ward','Martin Miller','Daniel Tindall','Jolene Swan','Belinda Harrison','Henry Brett','Carmella Whitehouse','Harmony Brown',
    'Chester Hunt','Manuel Oatway','Cara Young','Liam Vaughan','Luke Fleming','Carl Egerton','Ivette Kelly','Mark Oatway','Carla Shaw','Clarissa Curtis','Esmeralda Norris','Michelle Osmond','Jack Russell'
  ];
}

export function mockLastNames() {
  return ['Ramsay','Silber','Banane','Gianolini','Ibbett','Cornwell','Cutts','Pablos','Jiggins','Corsor','Puvia','Lissett','Grewes','Crosoer','Albinson','Osman','Goly',
  'Taylerson','Janauschek','Mackness','Bastie','OTimony','Beall','Hansie','Merriment','Carette','Borkett','Rickword','Toppin','Mongan','Frantz','Conduit','Fydo',
  'Keighley','Swains','Hearse','Haughton','Walls','Waddup','Kingzeth','Rymour','Fenn','Behn','Still','Esposita','Skrzynski','Capelen','de Villier','Jeannesson',
  'Kennea','Gass','Tubb','Elacoate','Taye','Stanning','Sturch','Gable','Abreheart','Baile','Jamme','Durkin','Gannicott','Brave','Hassett','Lyddy','Doxsey','Goodlatt',
  'LEpiscopi','Bodsworth','Temperton','Henker','Stapley','Basnall','Janku','Handmore','Gudgion','Roger','Butner','Dowears','Kornel','Juris','Synan','Sivyour','Reckus',
  'Trail','Levene','Bason','Ahlf','Lambal','Lindman','Danick','Swayland','Deakin','Hawking','Grahamslaw','Wheowall','Faragan','Standish-Brooks','Petrusch','Batkin'];
}

export function mockFirstNames() {
  return [
    'Hayden','Elise','Kamila','Josh','Anabelle','Cameron','Callie','Kaylee','David','Moira','Sebastian','Rick','Tiffany','Jayden','Nicholas','Rick','Angel','Cara','Daron','Gemma','Hope','Nancy','Paige','Mason',
    'Ryan','Janelle','Ruth','Anabel','Mya','Angela','Barry','Hank','Luke','Francesca','Gwenyth','Regina','Carter','Judith','Alma','Zara','Adalind','Melania','Maxwell','Melinda','Luna','Barney','Noah','Johnny',
    'Julius','Sarah','Rick','Phillip','Ronald','Elijah','Martin','Wade','Adina','Hannah','Makenzie','Danny','Juliet',
    'Destiny','Matthew','Nina','Eve','Daron','Dani','Zara','Teagan','Ryan','Eden','Aiden','Oliver',
    'Brad','Shelby','Javier','Julian','Mason','Kieth','Percy','Martin','Daniel','Jolene','Belinda','Henry','Carmella','Harmony','Chester','Manuel','Cara','Liam','Luke','Carl','Ivette','Mark','Carla','Clarissa',
    'Esmeralda','Michelle','Jack',
  ];
}
export function mockJobTitles() {
  return ['Account Representative III','Engineer II','Chemical Engineer','Human Resources Assistant II','Speech Pathologist','VP Accounting',
  'VP Product Management','Legal Assistant','Help Desk Operator','VP Product Management'];
}
export function mockShortMonths() {
  return ['Sep','Oct','Nov','Dec','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug'];
}
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
}

export function generateUUID():string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export function generateMockDrivers(amount): TyDriver[] {
  let drivers:TyDriver[] = [];
  let first_names = mockFirstNames();
  let last_names = mockLastNames();
  let jobs = mockJobTitles();
  for (let i = 0; i < amount; i++) {
    drivers.push({
      _id: generateUUID(),
			first_name: 'יוסי ישראלי', // first_names[getRandomInt(0, first_names.length)],
			last_name: last_names[getRandomInt(0, last_names.length)],
      job_title: jobs[getRandomInt(0, jobs.length)],
      avatar: 'https://randomuser.me/api/portraits/men/'+getRandomInt(10,80)+'.jpg',
      info: {
        email: 'kasndka.as@voipappz.com',
        organization: 'voipappz',
        phone: '972 123 1234 213',
        location: 'Tel Aviv, Israel',
        birthday: 'May 14th',
        'license_number': '9876543',
        'license_type': 'B',
        'license_expiration_date': '30.03.2025'

      },
      center_of_life: {
        center:[34.78,32.08],
        zoom:11,
        location:{
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {
                'title': 'Home'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [
                  34.770355224609375,
                  32.070647221609576
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {
                'title':'Work'
              },
              'geometry': {
                'type': 'Point',
                'coordinates': [
                  34.78889465332031,
                  32.08751958472376
                ]
              }
            }
          ]
        }
      },
      dashboards: {
        health_indicators: [
          [
            widgetFuel,
            widgetAvgCo2,
            widgetPersonalCorporate
          ],
          [
            widgetService,
            widgetOdemeter
          ],
          [
            widgetInc,
            widgetIncProf
          ]
        ],
        safety_indicators: [
          [
            widgetDrivingScore,
            widgetFuelScore
          ],
          [
            widgetIncPerMo,
            widgetIncProfDrv,
            widgetPersonalCorporateDrv
          ]
        ]
      },
      vehicle: {
        image:'/assets/media/ty/images/car_blue.png',
        model:'Toyota Prius C-1',
        type:'Hatchback Angular Front',
        license_number: 'HPP E057',
        status: [
          {
            title: 'Health',
            subtitle: '',
            sign: 'good'
          },
          {
            title: 'Connectivity',
            subtitle: 'Type: bla',
            sign: 'good'
          },
          {
            title: 'Tires',
            subtitle: 'Type: bla',
            sign: 'warn'
          },
          {
            title: 'Battery',
            subtitle: '',
            sign: 'good'
          },
          {
            title: 'Oil',
            subtitle: '',
            sign: 'bad'
          },
          {
            title: 'Warning Signals',
            subtitle: '',
            sign: 'good'
          }
        ]
      },
      contract: {
        information: contract
      }
		});
  }
  return drivers;
}

export function generateMockSingle(): Array<{}> {
  let series = [];
  let names = mockFirstNames();
  for (let i = 0; i < 5; i++) {
    series.push({
      'name': names[getRandomInt(0, names.length)],
      'value': getRandomInt(1000, 10000)
    });
  }
  return series;
}
export function generateMockSeries(): Array<{}> {
  let series = [];
  let months = mockShortMonths();
  for (let i = 0; i < 5; i++) {
    series.push({
      'name': months[i],
      'series': [{
        'name': 'Driver',
        'value': getRandomInt(1000, 10000)
      },
      {
        'name': 'Avarage',
        'value': getRandomInt(1000, 10000)
      }]
    });
  }
  return series;
}
export function generateMockMulti(): Array<{}> {
  let multi = [];
  let names = mockFirstNames();
  for (let i = 0; i < 5; i++) {
    multi.push({
      'name': names[getRandomInt(0, names.length -1)],
      'series': [{
        'name': 'shoulder',
        'value': getRandomInt(1000, 10000)
      },
      {
        'name': 'theft',
        'value': getRandomInt(1000, 10000)
      },
      {
        'name': 'malfunction',
        'value': getRandomInt(1000, 10000)
      },
      {
        'name': 'accident',
        'value': getRandomInt(1000, 10000)
      },
      {
        'name': 'speeding',
        'value': getRandomInt(1000, 10000)
      }]
    });
  }
  return multi;
}
/* export const widgetCounter:TyWidget = {
  icon: 'flaticon-trophy',
  title: 'This is an experiment',
  subtitle: 'the amount of bees in the world',
  value: '81,223',
  _type: {
    counterOnly: true
  }
}; */
/* export const widgetOptions:TyWidget = {
  icon: 'flaticon2-heart-rate-monitor',
  title: 'This is an experiment',
  subtitle: 'the amount of bees in the world',
  value: '81,223',
  _type: {
    optionsOnly:true,
    infoShow: true
  },
  info_title:'this is a test',
  info_subtitle:'just checking the widget',
  options: [
    {
      title: 'Previous',
      _selector: 'prev'
    },
    {
      title: 'Next',
      _selector: 'next'
    }
  ],
  values: {
    'prev': 'ABC 123',
    'next':'QWE 456'
  }
}; */
/* export const widgetPie:TyWidget = {
  icon: 'flaticon-trophy',
  title: 'This is an experiment',
  subtitle: 'the amount of bees in the world',
  value: '81,223',
  _type: {
    primary_chart:'pie'
  },
  chart_data: {
    'one_month': {
      primary: generateMockSingle()
    },
    'one_year': {
      primary: generateMockSingle()
    },
    'three_year': {
      primary: generateMockSingle()
    }

  }
}; */
/* export const widgetOptionsBarChart:TyWidget = {
  icon: 'flaticon-placeholder',
  title: 'This is an experiment',
  subtitle: 'the amount of bees in the world',
  value: '81,223',
  _type: {
    primary_chart:'grouped-barchart'
  },
  options: [
    {
      title: '24 Hr',
      _selector: 'day'
    },
    {
      title: 'Week',
      _selector: 'week'
    },
    {
      title: 'Month',
      _selector: 'month'
    },
    {
      title: 'Year',
      _selector: 'year'
    }
  ],
  chart_data: {
    'one_month': {
      primary: {
        'day': generateMockSeries(),
        'week': generateMockSeries(),
        'month': generateMockSeries(),
        'year': generateMockSeries(),
      }
    },
    'one_year': {
      primary: {
        'day': generateMockSeries(),
        'week': generateMockSeries(),
        'month': generateMockSeries(),
        'year': generateMockSeries(),
      }
    },
    'three_year': {
      primary: {
        'day': generateMockSeries(),
        'week': generateMockSeries(),
        'month': generateMockSeries(),
        'year': generateMockSeries(),
      }
    }

  }
}; */
/* export const widgetOptionsDoubleBarChart:TyWidget = {
  icon: 'flaticon-bus-stop',
  title: 'This is an experiment',
  subtitle: 'the amount of bees in the world',
  value: '81,223',
  _type: {
    primary_chart:'grouped-barchart',
    secondary_chart:'stacked-horizontal-barchart'
  },
  options: [
    {
      title: 'Worst',
      _selector: 'worst'
    },
    {
      title: 'Best',
      _selector: 'best'
    }
  ],
  chart_data: {
    'one_month': {
      primary: {
        'worst': generateMockSeries(),
        'best': generateMockSeries()
      },
      secondary: {
        'worst': generateMockMulti(),
        'best': generateMockMulti()
      }
    },
    'one_year': {
      primary: {
        'worst': generateMockSeries(),
        'best': generateMockSeries()
      },
      secondary: {
        'worst': generateMockMulti(),
        'best': generateMockMulti()
      }
    },
    'three_year': {
      primary: {
        'worst': generateMockSeries(),
        'best': generateMockSeries()
      },
      secondary: {
        'worst': generateMockMulti(),
        'best': generateMockMulti()
      }
    }

  }
}; */
