// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'humanize'})
export class HumanizePipe implements PipeTransform {
  transform(text) : any {
    //console.log("text", text)
    if (typeof text == 'boolean'){
					return text==true? "True" : "False";
				}
				if(text && typeof text=='string') {
            text = text.split("_");
            // go through each word in the text and capitalize the first letter
            for (var i in text) {
                var word = text[i];
                word = word.toLowerCase();
                word = word.charAt(0).toUpperCase() + word.slice(1);
                text[i] = word;
            }

            return text.join(" ");
        }
  }
}
