import { Injectable,Component, ElementRef } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { BehaviorSubject, Subject } from 'rxjs';

const client = algoliasearch(
  'YGKKU94FH1',
  'db22b046b1595d591d228a509c173fd7'
);
const indexDrivers = client.initIndex('ty_drivers');
const indexAlerts = client.initIndex('ty_fleet');

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public focus = new BehaviorSubject<string>('');
  public status = new Subject<string>();
  public data = new Subject<{all_segments:any[], params:any[], clear?:boolean, init?:boolean,param_to_add?:{field:string,value:any}}>();
  public filter = new Subject<any>();
  public params = new Subject<any[]>();
  public driverHits = new Subject<any[]>();
  public alertHits = new Subject<any[]>();
  public all_segments:any[]=[];
  public current_params:any[]=[];
  private el: ElementRef[]=[];
  constructor() {

  }
  public init(allSegments:any[], params:any[]){
    console.log("init search",allSegments , params)
    this.all_segments = allSegments;
    this.current_params = params;
    this.data.next({all_segments:this.all_segments, params:this.current_params,init:true})
  }
  public addParam(field, value){
    console.log("addParam",field, value)
    this.current_params.push({field:field,value:value})
    this.data.next({ all_segments:null, params:null,init:false, param_to_add:{field:field,value:value}})
  }
  clearParams(){
    this.current_params = [];
  }
  clear() {
    this.current_params = [];
    this.all_segments = [];
    this.driverHits.next(null);
    this.alertHits.next(null);
    this.data.next({all_segments:this.all_segments, params:this.current_params,clear:true})
  }

  drivers(q): Promise<any> {
    return new Promise((resolve, reject) => {
      return indexDrivers.search(
        {
          query: q,
          typoTolerance: false,
          hitsPerPage: 50,
        },
        (err, hits) => {
          if (err) {
            reject(err);
            throw err;
          }
          console.log(hits);
          this.driverHits.next(hits.hits);
          resolve(hits);
        }
      );
    });
  }
  alerts(q): any {
    return new Promise((resolve, reject) => {
      return indexAlerts.search(
        {
          query: q,
          typoTolerance: false,
          hitsPerPage: 50,
        },
        (err, hits) => {
          if (err) {
            reject(err);
            throw err;
          }
          console.log(hits);
          this.alertHits.next(hits.hits);
          resolve(hits);
        }
      );

    });
  }
  pushAlerts(obj) {
    indexAlerts.clearIndex().then(() => {
      indexAlerts.addObjects(obj, (err, content) => {
        console.log('%c Uploaded Alert Search Index', 'background: #222; color: #bada55');
        console.log(content);

        if (err) {
          console.error(err);
        }
      });
    });
    indexAlerts.setSettings(
      {
        searchableAttributes: [
          'vehicle_no',
          'vehicle_type',
          'driver',
          'status.label',
          'description',
          'type'
        ],
        // attributesToRetrieve: [
        //   '_id',
        //   'first_name',
        //   'last_name',
        //   'job_title'
        // ],
      },
      (err, res) => {
        console.log(res);
        if (err) {
          throw err;
        }
      }
    );
  }
  pushDrivers(obj) {
    indexDrivers.clearIndex().then(() => {
      indexDrivers.addObjects(obj, (err, content) => {
        console.log('%c Uploaded Drivers Search Index', 'background: #222; color: #bada55');
        console.log(content);

        if (err) {
          console.error(err);
        }
      });
    });
    indexDrivers.setSettings(
      {
        searchableAttributes: [
          'first_name',
          'last_name',
          'info.organization',
          'info.phone',
          'info.email',
          'vehicle.model',
          'vehicle.license_number'
        ],
        // attributesToRetrieve: [
        //   '_id',
        //   'first_name',
        //   'last_name',
        //   'job_title'
        // ],
      },
      (err, res) => {
        console.log(res);
        if (err) {
          throw err;
        }
      }
    );
  }
}
