import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FleetService } from '../../../../../core/_base/layout/services/fleet.service';

@Component({
  selector: 'kt-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Input() action: any;
  @Input() alertId: any;
  currentState = 'clickable';
  timestamp;
  constructor(private fleetSvc:FleetService,private ref: ChangeDetectorRef) { 

  }
  toggleAction() {
    console.log(this.fleetSvc.actionStates);

    if(this.currentState === 'clickable') {
      this.currentState = this.fleetSvc.actionStates[this.alertId][this.action.id].state = 'processing';
      this.timestamp = this.fleetSvc.actionStates[this.alertId][this.action.id].timestamp = new Date().getTime();
      this.fleetSvc.setActions();
      console.log('proccessing');
    }
  }
  ngOnInit() {
    if(!this.fleetSvc.actionStates[this.alertId]) {
      this.fleetSvc.actionStates[this.alertId] = {};
    }
    if(!this.fleetSvc.actionStates[this.alertId][this.action.id]) {
      this.fleetSvc.actionStates[this.alertId] = {
        [this.action.id]: {
          state: this.currentState ,
          timestamp: new Date().getTime()
        }
      };
      console.log('create');
    } else {
      this.currentState = this.fleetSvc.actionStates[this.alertId][this.action.id].state;
      this.timestamp = this.fleetSvc.actionStates[this.alertId][this.action.id].timestamp;
      console.log('didnt create');
    }
    this.fleetSvc.setActions();

    if(this.currentState === 'processing' && new Date().getTime() - this.timestamp > 3000) {
      this.currentState = this.fleetSvc.actionStates[this.alertId][this.action.id].state = 'completed';
      console.log('complete!!!');
      this.fleetSvc.setActions();
    } else {
      let interval = setInterval(() => {
        if(this.currentState === 'processing' && new Date().getTime() - this.timestamp > 3000) {
          this.currentState = this.fleetSvc.actionStates[this.alertId][this.action.id].state = 'completed';
          this.fleetSvc.setActions();
          console.log('complete');
          this.ref.detectChanges();
          clearInterval(interval);
        }
      }, 1000);
    }

    
  }

}
