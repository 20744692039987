// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { DriversComponent } from './drivers/drivers.component';
import { DriverComponent } from './drivers/driver/driver.component';
import { CallService, DriverService, FleetService, HandleRequest, ReportService, CampaignService, UserService } from '../../core/_base/layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FleetComponent } from './fleet/fleet.component';
import { DashboardsComponent } from './home/dashboards.component';
import { HomeComponent } from './home/dashboard/home.component';
import { SettingsComponent } from './settings/settings.component';
// external
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { WidgetModule } from '../partials/content/widgets/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { HybridComponent } from './hybrid/hybrid.component';
import { WebsocketService } from '../../core/_base/layout/services/action-cable.service';
import { ActionCableService } from 'angular2-actioncable'; 
import { hasClassName } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './reports/report/report.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignNumbersComponent } from './campaigns/numbers/campaign-numbers.component';
import { CallsComponent } from './calls/calls.component';
import { MatButtonModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatListModule, MatMenuModule, MatTabsModule, MatToolbarModule, MatToolbarRow, MatSliderModule, MatChipsModule, MatIconModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PlyrModule } from 'ngx-plyr';
import { UsersComponent } from './users/users.component';

@NgModule({
	declarations: [
		DriversComponent, 
		DriverComponent, 
		FleetComponent, 
		HomeComponent, 
		DashboardsComponent,
		SettingsComponent, 
		HybridComponent,
		ReportsComponent,
		ReportComponent,
		CallsComponent,
		CampaignsComponent,CampaignNumbersComponent,
		UsersComponent
	],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		NgbModule,
		CoreModule,
		RouterModule,
		PartialsModule,
		WidgetModule,
		NgxChartsModule,
		MatIconModule, MatChipsModule, MatSliderModule, MatSelectModule,MatTooltipModule, MatMenuModule,MatButtonModule,MatFormFieldModule,MatInputModule,MatCheckboxModule,MatTabsModule,MatListModule,MatToolbarModule,
		NgxDatatableModule,
		TranslateModule.forChild(),
		NgxMapboxGLModule.withConfig({
			accessToken: 'pk.eyJ1Ijoib21yaXZvaXBhcHB6IiwiYSI6ImNqdzRuYmlocTB0eGY0YW82ZTlvbDR1NjUifQ.kqCXTts1GKh0OZ4Zin2fGQ', // Optionnal, can also be set per map (accessToken input of mgl-map)
		}),
		PerfectScrollbarModule,
		PlyrModule
	],
	providers: [DriverService,
		FleetService, 
		ActionCableService ,
		WebsocketService, 
		HandleRequest, 
		ReportService,
		CampaignService,
		UserService,
		CallService,
		DatePipe
	]
})
export class PagesModule {
}
