import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FleetService, SearchService, TranslationService } from '../../../core/_base/layout';
import { Map, GeoJSONSourceRaw, HeatmapPaint, CirclePaint } from 'mapbox-gl';
import { TyFleet, TyAlert } from '../../../core/_base/layout/models/ty-fleet.model';
import { ActivatedRoute } from '@angular/router';
const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);
@Component({
  selector: 'kt-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit, AfterViewInit {
  loadingIndicator: boolean = false;
  reorderable: boolean = false;
  select;
  fleet: TyFleet;
  alerts: TyAlert[];
  _alertsOriginal: TyAlert[];
  lang;
  activeTab;
  marker = {
    parking: true,
    moving: true
  };

  layout = {
    'live': {
      'moving': {
        'icon-image': 'marker-blue',
        'icon-size': 0.9,
        'visibility': 'visible'
      },
      'parking': {
        'icon-image': 'marker-red',
        'icon-size': 0.9,
        'visibility': 'visible'
      }
    },
    'static': {
      'speeding': {
        'visibility': 'none'
      },
      'incidents': {
        'visibility': 'none'
      }
    },
    'geofences': {
      'red': {
        'visibility': 'none'
      },
      'blue': {
        'visibility': 'none'
      }
    }
  };

  imageLoaded = {
    parking: false,
    moving: false
  };



  paintHeatMap: HeatmapPaint = {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'mag'],
      0, 0,
      10, 1
    ],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, 1,
      15, 5
    ],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0, 'rgba(33,102,172,0)',
      0.2, 'rgb(103,169,207)',
      0.4, 'rgb(209,229,240)',
      0.6, 'rgb(253,219,199)',
      0.8, 'rgb(239,138,98)',
      1, 'rgb(178,24,43)'
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      0, 2,
      15, 20
    ],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      10, 1,
      15, 0
    ],
  };

  paintCircle: CirclePaint = {
    // Size circle radius by earthquake magnitude and zoom level
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7, [
        'interpolate',
        ['linear'],
        ['get', 'mag'],
        1, 1,
        6, 4
      ],
      16, [
        'interpolate',
        ['linear'],
        ['get', 'mag'],
        1, 5,
        6, 50
      ]
    ],
    // Color circle by earthquake magnitude
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'mag'],
      1, 'rgba(33,102,172,0)',
      2, 'rgb(103,169,207)',
      3, 'rgb(209,229,240)',
      4, 'rgb(253,219,199)',
      5, 'rgb(239,138,98)',
      6, 'rgb(178,24,43)'
    ],
    'circle-stroke-color': 'white',
    'circle-stroke-width': 1,
    // Transition from heatmap to circle layer by zoom level
    'circle-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7, 0,
      8, 1
    ]
  };

  speedingData: GeoJSONSourceRaw;
  incidentsData: GeoJSONSourceRaw;
  movingData: GeoJSONSourceRaw;
  geoFencesRedData: GeoJSONSourceRaw;
  geoFencesBlueData: GeoJSONSourceRaw;
  parkingData: GeoJSONSourceRaw;

  map: Map;
  isMapLoaded = false;
  @ViewChild('table') table;

  constructor(
    private fleetSvc: FleetService,
    private route: ActivatedRoute,
    private translate: TranslationService,
    private search: SearchService,
    private ref: ChangeDetectorRef) {

    this.loadingIndicator = true;
    this.select = 'one_month';

    this.search.alertHits.subscribe((hits) => {
      if (hits != null) {
        if (hits.length > 0) {
          this.alerts = hits;
        } else {
          this.alerts = [];
        }
      } else {
        this.alerts = this._alertsOriginal;
      }
      this.ref.detectChanges();
    });

    this.fleetSvc.getAll().subscribe((fleet: TyFleet) => {

      this.loadingIndicator = false;

      this.fleet = fleet;

      this.alerts = this._alertsOriginal = fleet.alerts;

      this.geoFencesBlueData = {
        type: 'geojson',
        data: this.fleet['map']['geofences_blue']
      };
      this.geoFencesRedData = {
        type: 'geojson',
        data: this.fleet['map']['geofences_red']
      };
      this.speedingData = {
        type: 'geojson',
        data: this.fleet['map']['speeding']
      };
      this.movingData = {
        type: 'geojson',
        data: this.fleet['map']['moving']
      };
      this.parkingData = {
        type: 'geojson',
        data: this.fleet['map']['parking']
      };
      this.incidentsData = {
        type: 'geojson',
        data: this.fleet['map']['incidents']
      };
    });
  }

  toggleMarker(tab, layer, isChecked) {
    this.layout[tab][layer].visibility = isChecked ? 'visible' : 'none';

    this.map.setLayoutProperty(layer, 'visibility', this.layout[tab][layer].visibility);

  }
  clearMarkers(tab) {
    for (let key in this.layout[tab]) {
      if (this.layout[tab].hasOwnProperty(key)) {
        this.layout[tab][key].visibility = 'none';
        this.map.setLayoutProperty(key, 'visibility', 'none');
      }
    }
  }
  selectMarker(tab, layer) {
    this.clearMarkers(tab);
    this.layout[tab][layer].visibility = 'visible';
    this.map.setLayoutProperty(layer, 'visibility', 'visible');

  }
  toggleMap(id) {
    if (this.isMapLoaded) {
      if (id === 'live') {
        this.map.setStyle('mapbox://styles/mapbox/streets-v9');
        this.clearMarkers('static');
      } else {
        this.map.setStyle('mapbox://styles/mapbox/dark-v10');
        this.clearMarkers('live');
        this.selectMarker('static', 'incidents');
      }
    }
  }
  toggleTab(id) {
    this.search.focus.next(id);
  }
  ngAfterViewInit() {

  }
  ngOnInit() {

    this.lang = this.translate.getSelectedLanguage();
    console.log(this.lang);
    this.route.queryParams.subscribe(params => {
      if(params.tab) {
        this.activeTab = params.tab;
        this.toggleTab(params.tab);
      }
    });
  }

}
