import { Injectable,Component, ChangeDetectorRef, AfterContentChecked, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { BaseComponent } from '../../../../views/themes/demo3/base/base.component';
import { SearchService } from '../../../../core/_base/layout/services/search.service';
import { MatMenuTrigger, MatOption } from '@angular/material';
import { isArray } from 'lodash';


const client = algoliasearch(
  'YGKKU94FH1',
  'db22b046b1595d591d228a509c173fd7'
);
const indexDrivers = client.initIndex('ty_drivers');
const indexAlerts = client.initIndex('ty_fleet');

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class SearchComponent implements OnInit, OnDestroy{
  @ViewChildren('allSelected') private allSelected: QueryList<MatOption>;
  @ViewChild(MatMenuTrigger) triggerFilter: MatMenuTrigger;
  private unsubscribe: Subscription[] = []; 
  searchQuery:string = '';
  searchFocus:string = '';
  public all_segments:any[]=[];
  public current_params:any[]=[];
  private all_segments_backup:any[]=[];
  private current_params_backup:any[]=[];
  _selectConditions = {
    numeric:[{title:'Equal',name:'EQ'},{title:'Greater Than',name:'GT'},{title:'Lower Than',name:'LT'},{title:'Not Equal',name:'NE'}],
    string:[{title:'Is',name:'IS'},{title:'Is Not',name:'IN'}]
  }
  appDirection:string = 'ltr';
  dataAvailable:boolean = false;
  constructor(private searchSvc:SearchService, private ref: ChangeDetectorRef, private base:BaseComponent) {
    console.log("init search ctor")
    this.appDirection = this.base.isRTL? 'rtl':'ltr';
  }
  ngOnDestroy(): void {
    console.log("SearchComponent ngOnDestroy")
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
  ngOnInit(){
    console.log(" SearchComponent ngOnInit")
    const focusSubscription = this.searchSvc.focus.subscribe((focus)=> {
      console.log("focus", focus)
      this.searchFocus = focus;
      if(this.triggerFilter) this.triggerFilter.closeMenu();
			this.ref.detectChanges();
		});
    this.unsubscribe.push(focusSubscription);
    
    const filterStatusSubscription = this.searchSvc.status.subscribe((status)=> {
      console.log("status", status)
      if(status=='ok'){
        this.current_params_backup = JSON.parse(JSON.stringify(this.current_params))//deep copy
        this.all_segments_backup = JSON.parse(JSON.stringify(this.all_segments)) //deep copy
      }else if(status=='error'){//error on filter
        this.current_params = JSON.parse(JSON.stringify(this.current_params_backup))//deep copy
        this.all_segments = JSON.parse(JSON.stringify(this.all_segments_backup))//deep copy
      }
			this.ref.detectChanges();
		});
    this.unsubscribe.push(filterStatusSubscription);

    const dataSubscription = this.searchSvc.data.subscribe(res=>{
      console.log("data init search this.params.subscribe",res)
        if(!res.param_to_add){
          this.all_segments = res.all_segments;
          let time_index=[]
          this.all_segments.map((s, index)=>{
            // console.log("data init search this.params.subscribe",s.value, res)

            /////////////////////////////////////////////////////////
            if(s.name == 'cause' && s.data && s.data[0] && s.data[0].uuid){//todo - nir should fix this on server side
              s.data = s.data.map(e=> {return {name: e.name, value: e.uuid}})
            }
            if(s.name == 'did_uuid' && s.value && s.value[0] && s.value[0].name && s.value[0].value){//todo - nir should fix this on server side
              s.data = s.value;
              s.value = [];
            }
            if(s.name == 'ivr_uuid' && s.value && s.value[0] && s.value[0].name && s.value[0].value){//todo - nir should fix this on server side
              s.data = s.value;
              s.value = [];
            }
            ////////////////////////////////////////////////////////////
            
            if(s.type=='time'){
              // s._raw_value={from:'',to:''}
              time_index.push(index)
            }
            if(!s.condition){
              if(s.operators){
                s.condition = s.operators[0];
              }else if(s.type=='numeric' || s.type=='string'){
                s.condition = this._selectConditions[s.type][0].name;
              }
            }
            if(res.init && s.data && isArray(s.data)){
              // console.log("data init search this.params.subscribe if",s, res)
              s.filteredOptions = (JSON.parse(JSON.stringify(s.data)))//deep copy
              // s.filteredOptions.map(obj=>obj.display=true)
            }
          })
          //remmove segments with type==time
          for(let i=0;i<time_index.length;i++){
            this.all_segments.splice(time_index[i],1)
          }
        }else{
          res.params = [res.param_to_add]
        }
        let params = [...this.current_params,...res.params]
        // console.log("000000con params", params)
        this.current_params = this.setCurrentParams(params);
        // console.log("current_params", this.current_params, this.all_segments)

        //// remove environment_uuid segment BEGIN
        let idx = -1;
        for(let i = 0; i<this.all_segments.length;i++){
          if(this.all_segments[i].name=='environment_uuid'){
            idx = i;
          }
        }
        if(idx>-1){
          this.all_segments.splice(idx, 1)
        }//// remove environment_uuid segment END
        if(res.init!=true && res.clear!=true){
          this.sendFilter();
        }else{
          this.current_params_backup = JSON.parse(JSON.stringify(this.current_params))//deep copy
          this.all_segments_backup = JSON.parse(JSON.stringify(this.all_segments))//deep copy
          if(res.clear){
            this.searchQuery = '';
            this.dataAvailable = false;
          }
          if(res.init){
            this.dataAvailable = true;
          }
        }
        
        
        this.ref.detectChanges();
      
    })
    this.unsubscribe.push(dataSubscription)
  }
  clearSearch() {
    this.searchQuery = '';
    this.current_params = [];
    // this.driverHits.next(null);
    // this.alertHits.next(null);
    let created_at_index = -1;
    //convert segments to URL PARAMS
    for(const { i, segment } of this.all_segments.map((segment, i) => ({ i, segment }))){
    // (let segment of this.segments) {
      // console.log("sendFilter- segment", segment, i)
      if(segment.type == 'time') {
        segment.condition = '';
        segment.value = (new Date(segment._raw_value.from).getTime()/1000) + "-" + (new Date(segment._raw_value.to).getTime()/1000);
        if(segment.field=="created_at") created_at_index = i;
      }
      if(segment.condition){
        segment.condition = '';
      }
      if(segment.value && typeof segment.value == 'string'){
        segment.value = '';
      }else if(segment.value && typeof segment.value == 'boolean'){
        segment.value = false;
      }else if(segment.value && Array.isArray(segment.value)){
        segment.value = []
      }
    }
    this.searchSvc.clearParams();
    this.all_segments_backup = JSON.parse(JSON.stringify(this.all_segments)) //deep copy 
    this.searchSvc.filter.next({params:null,_segments:this.all_segments, created_at_index:created_at_index, params_to_save:[]});
  }
  removeParam(index, segment){
    console.log("removeParam(index, segment)", index, segment)
    let s = this.all_segments.filter(p=>p.name==segment.field)
    if(s[0]){
      s[0].value = (s[0].type=='multi_select')? []:'';
    }
    this.current_params.splice(index,1);
    this.sendFilter();
  }
  setFiltersMenu(){

    // let _segment_type_select_array = this.all_segments.filter(s=>s.type.indexOf('multi_select')>-1)
    // this.all_segments.map(s=>{
    //   console.warn("data init search this.params.subscribe IFFFFFFFFF",s, s.type.indexOf('multi_select')>-1 , s.value , s.data )
    //   if(s.type.indexOf('multi_select')>-1 && s.value && s.data && s.value.length==s.data.length){
    //     s.selected = true;
    //     let segment_index = -1;
    //     for(let i=0;i<_segment_type_select_array.length;i++){
    //       if(s.name==_segment_type_select_array[i].name) {
    //         segment_index = i;
    //         break;
    //       }
    //     }
    //     console.warn("data init search this.params.subscribe IFFFFFFFFF",segment_index,this.allSelected.toArray(), this.allSelected.toArray()[segment_index])
    //     if(segment_index>-1){
    //       this.allSelected.toArray()[segment_index].select()
    //     }
    //   }
    // })
    
  }
  private setCurrentParams(params){
    //field label value
    let paramMap:any = {};
    let pArray:any = [];
    for(let i=0; i<params.length;i++){
      let s = this.all_segments.filter(p=>p.name==params[i].field)
      // console.log(params[i], s)
      if(s.length>0){
        // let _operator = (params[i].operator) ? params[i].operator : ( (s[0].operators)? s[0].operators[0] : this._selectConditions[s[0].type][0].name)
        let _operator = (params[i].operator)? params[i].operator : ( (s[0].operators)? ((s[0].operators.indexOf('IS')>-1)? 'IS' : s[0].operators[0]) : this._selectConditions[s[0].type][0].name)
        if(paramMap[params[i].field]){
          if(s[0].type=="multi_select"){
            var old_val = paramMap[params[i].field].value
            params[i].value = (params[i].value=='string')? [...old_val,...[params[i].value]] : [...old_val,...params[i].value];
            params[i].value = params[i].value.filter((item, idx) => params[i].value.indexOf(item) === idx)
          }else{
            // params[i].value = params[i].value ---> DO NOTHING
            pArray[paramMap[params[i].field].index].value = params[i].value;
            s[0].condition = pArray[paramMap[params[i].field].index].operator;
          }
        }else{
          if(s[0].type=="multi_select" && typeof params[i].value=='string'){
            params[i].value = [params[i].value]
          }else{
            params[i].value = params[i].value
          }
          params[i].operator = _operator;
          s[0].condition = params[i].operator;
          paramMap[params[i].field] = {...params[i],...{index:pArray.length}};
          pArray.push({field: params[i].field, label: s[0].label, value: params[i].value, operator:params[i].operator})
        }
        // console.log("con", _operator, params[i].operator, pArray, s)
        s[0].value = params[i].value;

      }
    }
    return pArray;
  }
  cancel(){
    this.all_segments = JSON.parse(JSON.stringify(this.all_segments_backup)) //deep copy 
  }
  sendFilter() {
    let created_at_index = -1;
    let params = {search:{}};
    this.current_params = []
    let params_to_save:any=[];
    //convert segments to URL PARAMS
    for(const { i, segment } of this.all_segments.map((segment, i) => ({ i, segment }))){
      let _p2save:any = {field:segment.name}
    // (let segment of this.segments) {
      // console.log("sendFilter- segment", segment, i)
      if(segment.value  && ((Array.isArray(segment.value)&& segment.value.length>0) || typeof segment.value=='string' ||  typeof segment.value=='boolean' )){
        if(segment.type == 'time') {
          segment.condition = '';
          segment.value = (new Date(segment._raw_value.from).getTime()/1000) + "-" + (new Date(segment._raw_value.to).getTime()/1000);
          if(segment.name=="created_at") created_at_index = i;
        }
        if(segment.condition) {
          if(!params['search'][segment.name]){
            params['search'][segment.name] = {};
          }
          params['search'][segment.name][segment.condition] = segment.value || '';
          _p2save.operator = segment.condition;
        }else {
          params['search'][segment.name] = segment.value || '';
        }
        _p2save.value = segment.value;
        params_to_save.push(_p2save)
        let _c_p:any = {...{field: segment.name}, ...segment}
        this.current_params.push(_c_p)
      }
    }
    params['action'] = 'filter';
    params['inline_search'] = this.searchQuery;
    // console.log(params);
    // console.log(this.all_segments, this.current_params)
    this.ref.detectChanges();
    // this.all_segments_backup = JSON.parse(JSON.stringify(this.all_segments)) //deep copy 
    this.searchSvc.filter.next({params:params,params_to_save:params_to_save, created_at_index:created_at_index});

  }
  searchSegmentData(query, options:{segment}){
    console.log("searchSegmentData",query, options.segment)
    if(!query || query==''){
      // options.segment.filteredOptions.map(obj=>{
      //     obj.display=true;
      // })
      options.segment.filteredOptions = (JSON.parse(JSON.stringify(options.segment.data)))//deep copy
    }else{
      options.segment.filteredOptions = (options.segment.data.filter(obj=>obj.name.toLowerCase().indexOf(query.toLowerCase())>-1))
      // options.segment.filteredOptions.map(obj=>{
      //   if(obj.name.toLowerCase().indexOf(query.toLowerCase())>-1){
      //     obj.display=true;
      //   }else{
      //     obj.display=false;
      //   }
      // })
    }
    // this.ref.detectChanges();
  }
  toggleSelectAll(selectAllValue: boolean, segment) {
    // this.filteredBanksMulti.pipe(take(1), takeUntil(this._onDestroy))
    //   .subscribe(val => {
    //     if (selectAllValue) {
    //       this.bankMultiCtrl.patchValue(val);
    //     } else {
    //       this.bankMultiCtrl.patchValue([]);
    //     }
    //   });
    segment.filteredOptions.map(obj=>{
      obj.display=selectAllValue;
    })
  }
  displaySegmentValue(segment){
    // console.log(segment)
    let s = this.all_segments.filter(p=>p.name==segment.field)
    // console.log(segment, s)
    if(s.length>0){
      return this.getVal(segment.value,s[0]);
    }else{
      return null;
    }
  }
  getVal(param, s){
    if (!param) return null;
    if(typeof param == 'string'){ 
      return param
    }else if(typeof param == 'object'&& param.length>0){
      let p:string;
      if(s.type.indexOf('multi_select')>-1 && s.data){
        let pObj = s.data.filter(obj=>obj.value==param[0]);
        p = (pObj[0])? pObj[0].name : param[0];//TODO - ask nir if its a bug - value in column(timeout in cause for example) isnt in segment data (search options)
      }else if(s.type == 'select' && s.data){
        let pObj = s.data.filter(obj=>obj.value==param);
        p = pObj[0].name;
      }else{//boolean
        //TODO
      }
      // let p = (typeof param[0]=='string')? param[0] : param[0].name
      return p+ (param.length>1? '(+'+param.length+')' : '')
    }else return null;

  }
  togglePerOne( segment,segment_index){ 
    console.log("000000000 togglePerOne",  segment,segment_index)
    let _segment_type_select_array = this.all_segments.filter(s=>s.type.indexOf('multi_select')>-1)
    for(let i=0;i<_segment_type_select_array.length;i++){
      if(segment.name==_segment_type_select_array[i].name) {
        segment_index = i;
        break;
      }
    }
    if(segment.selected) {  
      segment.selected=false
      this.allSelected.toArray()[segment_index].deselect()
      // return false;
    }else 
    if(segment['value'].length==segment.data.length){
      this.allSelected.toArray()[segment_index].select();
      segment.selected=true
    }
  }
   toggleAllSelection(segment) {
    console.log("00000000000 toggleAllSelection", segment)
    segment.selected = !segment.selected
     if (segment.selected) {
      segment['value']=segment.data.map(obj=>obj.value)
     } else {
      segment['value']=[]
     }
   }
}
