import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'form-dialog',
    templateUrl: 'form-dialog.component.html',
    styleUrls:['form-dialog.component.scss']
  })
  export class FormDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FormDialog>) {
        console.log("FORM DIALOG data", data)
        
    }

    close(){
        this.dialogRef.close();
    }
    send(){
        let formData:any = {};
        for(let i=0;i<this.data.fields.length;i++){
            formData[this.data.fields[i].key] = this.data.fields[i].value;
        }
        console.log("send",formData,this.data )
        this.dialogRef.close(formData);
    }
  }