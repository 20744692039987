// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debug } from 'util';
import { HandleRequest } from '../../layout/services/handleRequest.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { Logout } from '../../../auth/_actions/auth.actions';
import { MessageService } from '../../layout/services/message.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(private store:Store<AppState>, private msgSvc:MessageService){}
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					console.log("--------------------",error);
					let status = error.status;
					let body = error.error;
					body = (typeof body === 'object' && 'message' in body) ? body : HandleRequest.parseIfValidJson(body) || { message: "Server Error" };
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					console.error(error.status);
					console.error(error.message);
					// console.log('--- end of response---');
					switch (status) {
						// case 666:
						//   this.lostConnection(body.message, options.retry_timeout);
						//   break;
						case 401:
						  console.log("handleErrors 401",typeof body,body,typeof status, status)
						  // this.events.publish("app:logout");
						  this.store.dispatch(new Logout());
						  // break;
						case 0:
						case 404:
						case 500:
						//   this.alertCtrl
						//     .create({
						//       title: body.message,
						//       subTitle: status.toString() +" "+this.translate.instant('ALERT.SUPPORT_MSG'),//" Please contact support",
						//       buttons: [this.translate.instant('ALERT.DISMISS')]//["Dismiss"]
						//     })
						//     .present();
						//   break;
						// case 501://upload file - file type error
						//   this.alertCtrl
						//     .create({
						//       title: body.message.title,
						//       subTitle: body.message.sub_title,//status.toString(),
						//       buttons: [this.translate.instant('ALERT.OK')]//["Ok"]
						//     })
						//     .present();
						//   break;
						//   case 502://webrtc phone - user media error
						//   this.alertCtrl
						//     .create({
						//       title: body.message,
						//       // subTitle: body.message.sub_title,//status.toString(),
						//       buttons: [this.translate.instant('ALERT.OK')]//["Ok"]
						//     })
						//     .present();
						//   break;
						default:
						  // // toast = this.toastCtrl
						  // //   .create({
						  // //     message: body.message,
						  // //     showCloseButton: true,
						  // //     position: "top",
						  // //     cssClass: "toast-danger"
						  // //   });
						  // // this.handleAlerts(toast);
						  // this.events.publish("app:notifications:error", body);
						  this.msgSvc.show(body.message, 'error');
						  break;
					  }
					  if(error.url.indexOf("/auth/token_status")>-1){
						this.store.dispatch(new Logout());
					  }
					  console.log(
						"%c " + status + ": " + body.message,
						"background: #222; color: #bada55;font-size:16px;"
					  );
				}
			)
		);
	}
}
