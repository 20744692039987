// Angular
import { Component, Inject, OnInit } from '@angular/core';
// NGRX
import {  Store } from '@ngrx/store';
// State
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../core/auth';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
	selector: 'kt-message',
	templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
	
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private store: Store<AppState>) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		
	}
}
