import { Injectable } from '@angular/core';
import { Observable,of, Subject, Subscription } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import { WebsocketService } from './action-cable.service';
import { HandleRequest } from './handleRequest.service';
import { Store, select } from '@ngrx/store';
import { currentUser } from '../../../auth/_selectors/auth.selectors';
import { AnySourceImpl } from 'mapbox-gl';

@Injectable()
export class DashboardService {
  private account:any;
  private current_chanel:string = ''
  private subscritionArray:Subscription[] = [];
  public widgetData = new Subject<any>();//<WidgetData>();
    // public static COMPONENT_MAP = {
    //     table: TableWidget,
    //     min_table: TableWidget,
    //     // counter_multi: CounterWidget,
    //     counter: CounterWidget,
    //     pie: PieChartWidget,
    //     line: LineChartWidget
    //   };
    constructor(private handleRequest:HandleRequest, private ws:WebsocketService, private store: Store<AppState>,) {
      this.subscritionArray.push(this.store.pipe(select(currentUser))
        .pipe(filter(account => account != null))
        .subscribe(account => {
          console.log("get account dashboard service subscriber", account)
          if(account){
            this.account = account;
          }
       })
      )
    }
    ngOnDestroy(){
      this.subscritionArray.map(s=>s.unsubscribe());
    }
    public getDashboards(): Promise<any> {
      return this.handleRequest.get("/api/dashboards").toPromise()
    }
    public get(dashboard='', uuid=''): Promise<any> {
      // console.log("get account dashboard")
      if(dashboard && dashboard!='' && uuid==''){
        return this.handleRequest.get("/api/dashboards/"+dashboard+"?action=widgets").toPromise()
      }else if(uuid!=''){
        return this.handleRequest.get("/api/dashboards/"+uuid+"?action=widgets").toPromise()
      }else if(!this.account){
        // console.log("get account dashboard !this.account")
        // const accountDataSubscription = this.store.pipe(select(currentUser))
        //   .pipe(
        //     filter(account => account != null),
        //     map((account)=>{
        //       console.log("get account dashboard !this.account store.pipe", account)
        //       this.account = account;
        //       return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=widgets")
        //     }))
        //   .subscribe(account => {
        //     console.log("get account dashboard !this.account store.pipe", account)
        //     accountDataSubscription.unsubscribe();
        //     return account;
        //     // this.account = account;
            
        //     // return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=widgets")
                  
        // });

        return new Promise(async (resolve) => {
          const accountDataSubscription = this.store.pipe(select(currentUser))
          .pipe(
            filter(account => account != null),
            map((account)=>{
              // console.log("get account dashboard !this.account store.pipe", account)
              this.account = account;
              return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=widgets").toPromise()
            }))
          .subscribe(account => {
            // console.log("get account dashboard !this.account store.pipe", account)
            accountDataSubscription.unsubscribe();
            resolve(account);
          });
        });
      }else{
        // console.log("get account dashboard this.account", this.account)
        return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=widgets").toPromise()
      }
    }
    public getParams(dashboard='', uuid=''): Promise<any> {
      // console.log("hhhhhhhhhhhhhh getParams", dashboard)
      if(dashboard && dashboard!='' && uuid==''){
        return this.handleRequest.get("/api/dashboards/"+dashboard+"?action=params")
              //   .map(res => res.json())
                  .toPromise();
      }else if(uuid!=''){
        return this.handleRequest.get("/api/dashboards/"+uuid+"?action=params").toPromise()
      }
      else if(!this.account){
        // this.store.pipe(select(currentUser))
        //   .subscribe(account => {
        //     if(account){
        //       this.account = account;
        //       return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=params")
        //       //   .map(res => res.json())
        //           .toPromise();
        //     }
        // });
        return new Promise(async (resolve) => {
          const accountDataSubscription = this.store.pipe(select(currentUser))
          .pipe(
            filter(account => account != null),
            map((account)=>{
              // console.log("get account dashboard !this.account store.pipe", account)
              this.account = account;
              return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=params").toPromise()
            }))
          .subscribe(account => {
            // console.log("get account dashboard !this.account store.pipe", account)
            accountDataSubscription.unsubscribe();
            resolve(account);
          });
        });
      }else{
        return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=params")
      //   .map(res => res.json())
          .toPromise();
      }
      
    }
    public saveParams(dashboard='',params,uuid): Promise<any>{
      if(dashboard!=''  && uuid==''){
        return this.handleRequest.patch("/api/dashboards/"+dashboard+"?action=save_params", params)
              //   .map(res => res.json())
                  .toPromise();
      }else if(uuid){
        return this.handleRequest.patch("/api/dashboards/"+uuid+"?action=save_params", params).toPromise()
      }else if(!this.account){
        return new Promise(async (resolve) => {
          const accountDataSubscription = this.store.pipe(select(currentUser))
          .pipe(
            filter(account => account != null),
            map((account)=>{
              // console.log("get account dashboard !this.account store.pipe", account)
              this.account = account;
              return this.handleRequest.patch("/api/dashboards/"+this.account.dashboard.uuid+"?action=save_params", params)
            }))
          .subscribe(account => {
            // console.log("get account dashboard !this.account store.pipe", account)
            accountDataSubscription.unsubscribe();
            resolve(account);
          });
        });
      }else{
        return this.handleRequest.patch("/api/dashboards/"+this.account.dashboard.uuid+"?action=save_params", params).toPromise()
      }
    }
    public getSegments(dashboard='', uuid=''): Promise<any> {
      // console.log("hhhhhhhhhhhhhh getSegments", dashboard)
      if(dashboard!=''  && uuid==''){
        return this.handleRequest.get("/api/dashboards/"+dashboard+"?action=segments")
              //   .map(res => res.json())
                  .toPromise();
      }else if(uuid!=''){
        return this.handleRequest.get("/api/dashboards/"+uuid+"?action=segments").toPromise()
      }else if(!this.account){
        // this.store.pipe(select(currentUser))
        //   .subscribe(account => {
        //     if(account){
        //       this.account = account;
        //       return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=segments")
        //             .toPromise();
        //     }
        // });
        return new Promise(async (resolve) => {
          const accountDataSubscription = this.store.pipe(select(currentUser))
          .pipe(
            filter(account => account != null),
            map((account)=>{
              // console.log("get account dashboard !this.account store.pipe", account)
              this.account = account;
              return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=segments").toPromise()
            }))
          .subscribe(account => {
            // console.log("get account dashboard !this.account store.pipe", account)
            accountDataSubscription.unsubscribe();
            resolve(account);
          });
        });
      }else{
        return this.handleRequest.get("/api/dashboards/"+this.account.dashboard.uuid+"?action=segments")
      //   .map(res => res.json())
          .toPromise();
      }
      
    }
    public leave(channel='dashboard_admin') {
        this.ws.leave(channel);
      }
    
    public join(key:string=null, value?:string):string {
        let current_channel = 'dashboard_admin';
        if(key){
          current_channel = 'dashboard_'+key;
        }
        this.store.pipe(select(currentUser))
        .pipe(
        	tap(account => {
            if(account){
              let data:any = {account_uuid:account.uuid}
            if(key){
              data[key+'_uuid']=value;
            }
            this.ws.connect();
            this.ws.join(current_channel,data);
            this.ws.getChannel(current_channel).received()
            .subscribe(data => {
                this.widgetData.next(data);
            },
            err=>{
              console.error(err);
            });
        
            this.ws.getChannel(current_channel).connected()
            .subscribe(() => {
              this.ws.getChannel(current_channel).perform('login',data);
            },
            err=>{
              console.error(err);
        
            });
            }
         })).subscribe();
         return current_channel;
    
    }
    link(url, type){//:Promise<any> {
      // if(type.indexOf("post")>-1){
      //   return this.handleRequest.post(url, {}, {force_url:true})
      //   .map(res => res.json())
      //   .toPromise();
      // }else if(type.indexOf("delete")>-1){
      //   return this.handleRequest.delete(url, {force_url:true})
      //   .map(res => res.json())
      //   .toPromise();
      // }else{
      //   console.log("from server: " + url)
      //   // var firstIndex = url.indexOf("https");
      //   // url = firstIndex != url.lastIndexOf("https") && firstIndex != -1;
      //   return this.handleRequest.getAll(url, {force_url:true})
      //   .map(res => res.json())
      //   .toPromise();
      // }
    }   
  
}
