import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    CustomerLoaded = '[Load Customer] action'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { authToken: string, user:any}) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
    constructor() { console.log("AuthActionTypes Logout")}
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: { authToken: string }) { }
}


export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
    // constructor() { console.log("AuthActionTypes UserRequested")}
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { 
        // console.log("AuthActionTypes UserLoaded")
    }
}

export class CustomerLoaded implements Action {
    readonly type = AuthActionTypes.CustomerLoaded;
    constructor(public payload: { data: any }) { 
        console.log("AuthActionTypes CustomerLoaded")
    }
}


export type AuthActions = Login | Logout | Register | UserRequested | UserLoaded | CustomerLoaded;
