import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'kt-hybrid',
  templateUrl: './hybrid.component.html',
  styleUrls: ['./hybrid.component.scss']
})
export class HybridComponent implements OnInit {
  url: string = "https://test-drive-stg.toyotaconnectedeurope.io";
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
