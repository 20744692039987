import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  QueryList,
  ElementRef,
  ViewChildren,
  Optional,
  Inject
} from '@angular/core';
import {
  CampaignService,
  Events,
  MessageService,
  SearchService
} from '../../../core/_base/layout';
import {
  Router,
  ActivatedRoute,
  Route, NavigationStart, NavigationEnd
} from '@angular/router';
import {
  TyCampaign
} from '../../../core/_base/layout/models/ty-campaign.model'//'../../../core/_base/layout/models/ty-campaign.model';
import { EnvironmentService } from '../../../core/_base/layout/services/environmet.service';
import { Observable, of, Subscription } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormDialog, SelectDialog } from '../../partials/content/crud';
import { BaseComponent } from '../../themes/demo3/base/base.component';

@Component({
  selector: 'kt-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  activeTab:string = 'dashboard'
  active_campaign_uuid:string = '';
  active_campaign: TyCampaign;
  campaigns: TyCampaign[];
  campaigns$:Observable<TyCampaign[]>;
  searchQuery:string=''
  _campaignsOriginal: TyCampaign[];
  dataAvaliable:boolean=false;;
  loading:boolean=true;
  workflows:any=[]
  show_menu:boolean=false
  // showWorkflowEvents:boolean = false;
  showWorkflowRatio:boolean=false;
  workflow_events:any=[]
  campaign_workflow:any = {};
  campaign_workflow_event:any = {};
  appDirection:string = 'ltr'
  subscriptionArray:Subscription[] = [];
  @ViewChildren('campaignsElements') private campaignsElements: QueryList<ElementRef>;
  constructor(private router:Router, private campaignsvc: CampaignService, private activeRoute: ActivatedRoute,
              private search: SearchService, public dialog: MatDialog,
              @Optional() @Inject(BaseComponent) private base, private events:Events,
              private ref: ChangeDetectorRef, private msgSvc:MessageService) {
    this.appDirection = (this.base && this.base.isRTL)? 'rtl':'ltr';           
    // this.search.focus.next('campaigns');
    this.loadCampaigns();
    const activeRouteParams = this.activeRoute.params
		.subscribe(params=> {
			console.log("activeRoute.params9999999999999", params);
			
		});
    this.subscriptionArray.push(activeRouteParams)

    this.events.publish("screen-changed",{name:"campaigns", toggle:true})
    this.events.subscribe("toggle-menu", (data)=>{
      this.show_menu = data
    })
    const routerEvents = this.router.events
    .pipe(
      filter( event =>event instanceof NavigationEnd)
    )
  .subscribe(
    (event:NavigationEnd) => {
      console.log("router.events99999999999999999", event);
      // if(event instanceof NavigationStart) {
      //   console.log(event);
      // }
      if(event.urlAfterRedirects.indexOf('campaigns')>-1 && event.urlAfterRedirects.indexOf('numbers')==-1){
        this.reload();
        this.activeTab='dashboard'
      }else{
        this.activeTab='numbers'
      }
    });
    this.subscriptionArray.push(routerEvents)
    
    // this.search.campaignHits.subscribe((hits) => {
    //   if(hits != null) {
    //     if(hits.length > 0) {
    //       this.campaigns = hits;
    //     } else {
    //       this.campaigns = []; 
    //     }
    //   } else {
    //     this.campaigns = this._campaignsOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
  }

  runCampaign(campaign){
    console.log("runCampaign", campaign)
    let status = campaign.status;
    let uuid = campaign.uuid;
    let action = (status=='run')? 'stop':'run';
    this.campaignsvc.run(action, uuid)
    .then(campaign => {
      this.active_campaign = campaign;
      this.campaignsvc.setActiveCampaign(this.active_campaign)
        // this.loading = false;
        let campaign_from_list = this.campaigns.filter(c=>c.uuid == this.active_campaign_uuid)[0]
        if(campaign_from_list){
          campaign_from_list.status = campaign.status;
        }
        this.msgSvc.show("Campaign updated successfully")
        this.ref.detectChanges();
      console.info(campaign); 
    })
    .catch(err => {
      console.error(err);
    });
    //this.events.publish("campaign:action");
  }
  searshCampaigns(query){
    this.campaigns$ = of(this.campaigns).pipe(
      // delay(2000),
      map(c => {
        // if filter is empty return all campaigns
        if (query=='') {
          return c;
        }
    
        // search for specific campaigns_
        const filteredCampaigns: TyCampaign[] = [];
    
        c.filter(function(campaign) {
          if (campaign.name.toLowerCase().includes(query.toLowerCase())) {
            filteredCampaigns.push(campaign);
          }
        });
    
        return filteredCampaigns;
      })
    );
  }
  campaign_profile_ratio
  reload(){
    if(this.activeTab=='numbers') return;
    console.log("reload00000000000000000000", this.active_campaign_uuid, this.activeRoute.snapshot['_routerState'].url)
    this.loading = true;
    this.workflow_events=[]
    this.workflows=[]
    this.campaign_workflow_event={}
    this.campaign_workflow = {}
    // this.showWorkflowEvents = false
    this.campaignsvc.getByUuid(this.active_campaign_uuid).toPromise().then(
      campaign=>{
        this.active_campaign = campaign;
        this.campaignsvc.setActiveCampaign(campaign)
        this.loading = false;
        let campaign_from_list = this.campaigns.filter(c=>c.uuid == this.active_campaign_uuid)[0]
        if(campaign_from_list){
          campaign_from_list.status = campaign.status;
        }
        this.ref.detectChanges();
      },
      err=>{
        this.loading = false;
        this.ref.detectChanges();
      }
    )
    // this.campaignsvc.getWorkflow(this.active_campaign_uuid).toPromise()
    // .then(res=>{
    //   this.workflows = res
    // })
    this.campaignsvc.getWorkflowEvents(this.active_campaign_uuid).toPromise()
      .then(res=>{
        this.workflow_events = res;
        if(res.length>0){
          this.campaign_workflow_event = res[0]
          this.eventChanged({value:res[0]})
        }
        this.ref.detectChanges();
      })
  }
  
  // workflowChanged(e){
  //   console.log("ffffffffffffff", e.value, this.campaign_workflow)
  //   // this.showWorkflowEvents = false
  //   if(e.value){
  //     if(this.campaign_workflow.profile){
  //       this.campaign_workflow.profile.ratio = (this.campaign_workflow.profile.ratio)? this.campaign_workflow.profile.ratio : 50;
  //     }else{
  //       this.campaign_workflow.profile={ratio:50}
  //     }
  //     this.campaignsvc.getWorkflowEvents(this.campaign_workflow.uuid).toPromise()
  //     .then(res=>{
  //       this.workflow_events = res;
  //       // this.showWorkflowEvents = true
  //       this.ref.detectChanges();
  //     })
  //   }
  // }
  closeMenu(){
    this.events.publish("toggle-menu", false)
    this.show_menu=false;
  }
  toggleTab(id) {
    // this.search.focus.next(id);
    let lang = (this.appDirection=='ltr')? 'en' : 'he'
    if(id=='dashboard'){
      this.router.navigate([lang+'/app/campaigns',this.active_campaign_uuid,'dashboard','campaign']);
    }
    if(id=='numbers'){
      this.campaignsvc.setCampaignNumbersMode('tab')
      this.router.navigate([lang+'/app/campaigns',this.active_campaign_uuid,'numbers'],);
    }
    this.activeTab = id
  }
  event_segments:any;
  event_params:any;
  eventChanged(e){
    console.log("ffffffffffffff", e.value, this.campaign_workflow_event)
    this.showWorkflowRatio = false
    if(e.value){
      this.campaign_workflow = e.value.workflow
      if(this.campaign_workflow.profile && (this.campaign_workflow.profile.max_ratio || this.campaign_workflow.profile.min_ratio | this.campaign_workflow.profile.ratio)){
        // if(this.campaign_workflow.profile.max_ratio && this.campaign_workflow.profile.min_ratio && this.campaign_workflow.profile.max_ratio == this.campaign_workflow.profile.min_ratio){
        //   this.campaign_workflow.profile.max_ratio = +this.campaign_workflow.profile.max_ratio+10
        // }
        if(!this.campaign_workflow.profile.max_ratio){
          this.campaign_workflow.profile.max_ratio = 100
        }
        if(!this.campaign_workflow.profile.min_ratio){
          this.campaign_workflow.profile.min_ratio = 0
        }
        if(!this.campaign_workflow.profile.ratio){
          this.campaign_workflow.profile.max_ratio = 50
        }
        if(parseInt(this.campaign_workflow.profile.max_ratio) < parseInt(this.campaign_workflow.profile.min_ratio)){
          this.campaign_workflow.profile.max_ratio = this.campaign_workflow.profile.min_ratio//+10
        }
        if(parseInt(this.campaign_workflow.profile.ratio)<parseInt(this.campaign_workflow.profile.min_ratio)){
          this.campaign_workflow.profile.ratio = this.campaign_workflow.profile.min_ratio
        }else if(parseInt(this.campaign_workflow.profile.ratio)>parseInt(this.campaign_workflow.profile.max_ratio)){
          this.campaign_workflow.profile.ratio = this.campaign_workflow.profile.max_ratio
        } 
      }else{
        this.campaign_workflow.profile={ratio:50, max_ratio:100, min_ratio:0}
      }
      this.showWorkflowRatio = true
      this.campaignsvc.getWorkflowEventSegments(this.campaign_workflow_event.uuid).toPromise()
      .then(res=>{
        this.event_segments = res;
        this.campaignsvc.getWorkflowEventParams(this.campaign_workflow_event.uuid).toPromise()
        .then(res=>{
          res=res.map(p=>{return {field: p.field,
          operator: p.operator,
          value:p.val}})
          this.event_params = res;
          this.setSearch();
          this.ref.detectChanges();
        })
      })
      
    }

  }
  setSearch(){
    this.search.focus.next('campaign');
    this.search.init(this.event_segments,this.event_params)
    const searchSubscription = this.search.filter.subscribe(data=>{
      console.log("this.search.filter", data)
      if(data) {
        let _filters:any;
        if(data.params){
          _filters= data.params
        }else{
          // this.params = [];
        }
        // this.filters = _filters;
        this.saveParams(data.params_to_save);
      }
    })
    this.subscriptionArray.push(searchSubscription);
  }
  saveParams(params_to_save){
    this.campaignsvc.saveParams( this.campaign_workflow_event.uuid,{params:params_to_save}).toPromise().then((res)=>{
      this.search.status.next('ok')
    },(err)=>{
      this.search.status.next('error')
    }).catch(err=>{console.error(err)})
  }
  ratioChanged(event, profile_field){
    let data:any = {profile:{}};
    if(profile_field=='min_ratio'){
      if(this.campaign_workflow.profile["min_ratio"]>this.campaign_workflow.profile["max_ratio"]){
        this.campaign_workflow.profile["min_ratio"] = this.campaign_workflow.profile["max_ratio"]
      }
      if(this.campaign_workflow.profile["min_ratio"]>this.campaign_workflow.profile["ratio"]){
        this.campaign_workflow.profile["ratio"] = this.campaign_workflow.profile["min_ratio"]
      }
    }else if(profile_field=='max_ratio'){
      if(this.campaign_workflow.profile["min_ratio"]>this.campaign_workflow.profile["max_ratio"]){
        this.campaign_workflow.profile["max_ratio"] = this.campaign_workflow.profile["min_ratio"]
      }
      if(this.campaign_workflow.profile["max_ratio"]<this.campaign_workflow.profile["ratio"]){
        this.campaign_workflow.profile["ratio"] = this.campaign_workflow.profile["max_ratio"]
      }
    }
    // data.profile[profile_field] = this.campaign_workflow.profile[profile_field]
    data.profile["ratio"] = this.campaign_workflow.profile["ratio"]
    data.profile["min_ratio"] = this.campaign_workflow.profile["min_ratio"]
    data.profile["max_ratio"] = this.campaign_workflow.profile["max_ratio"]
    console.log("ffffffffffffff ratioChanged", event, this.campaign_workflow.profile.ratio)
    this.campaignsvc.updateWorkflow(this.campaign_workflow.uuid, data)
      .then(res=>{
        this.event_segments = res;
        this.ref.detectChanges();
      })
  }
  loadCampaigns(options:{reloadChild?:boolean} = {reloadChild:false}){
    if(this.activeRoute.snapshot['_routerState'].url.indexOf('campaigns')>-1 && this.activeRoute.snapshot['_routerState'].url.indexOf('dashboard/campaign')>-1){
      this.activeTab='dashboard'
    }else{
      this.activeTab='numbers'
    }
    this.campaignsvc.get().then((campaigns) => {
      this.campaigns = this._campaignsOriginal = campaigns;
      this.campaigns$ = of(this.campaigns)
      this.dataAvaliable = true;
      this.ref.detectChanges();
      if(this.campaigns && this.campaigns.length>0){
        let url = this.activeRoute.snapshot['_routerState'].url
        let urlArray = url.split("/")
        if(this.campaigns.length>0 && (urlArray.length<5 || options.reloadChild)){
          ((this.campaignsElements.toArray())[0].nativeElement as HTMLElement).click()
        }
      }
      let url = this.activeRoute.snapshot['_routerState'].url
      let urlArray = url.split("/")
      // console.log("reload00000000000000000000 loadCampaigns", this.active_campaign_uuid, this.activeRoute.snapshot['_routerState'].url, urlArray)
      if(urlArray.length>4){
        this.active_campaign_uuid = this.activeRoute.snapshot['_routerState'].url.split('/')[4];
        this.reload();// this.active_campaign = this.campaigns.filter(c=>c.uuid == this.active_campaign_uuid)[0]
      }
      
      
    });
  }
  isActive(campaign){
    let url = this.activeRoute.snapshot['_routerState'].url
    // console.log("getRouterLink",url.indexOf(campaign.uuid)>-1, url)
    if(url.indexOf(campaign.uuid)>-1){
      return true
    }
    return false
  }
  onRouterLinkActive(data){//[event, uuid]
    this.active_campaign_uuid = data[1];
    console.log(" onRouterLinkActive9999999999999", this.active_campaign_uuid, this.activeRoute.snapshot['_routerState'].url)
    
    // this.reload();//this.active_campaign = this.campaigns.filter(c=>c.uuid == this.active_campaign_uuid)[0]
  }
  
  duplicateCampaign(campaign){
    console.log('duplicateCampaign :'); 
    
        let dialogRef = this.dialog.open(FormDialog, {
          // height: '400px',
          width: '300px',
          data: {
            title: "Duplicate campaign",
            fields:[
              {
                type:'string',
                label:'name',
                key:'name',
                required:true,
                placeholder: "Name"
              }
            ]
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result:`, result); 
          if(result){
            result.action='duplicate'
            result.uuid = this.active_campaign_uuid
            this.campaignsvc.duplicate(result).then(res=>{
              this.msgSvc.show("Campaign created successfully")
              this.loadCampaigns();
            });
          }
        });
    
  }
  editCampaign(field){
    let options:any=[]
    // if(field =='did'){
    //   this.campaignsvc.getData('dids').then(res=>{
    //     options = res
    //   })
    // }else if(field =='user'){
    //   this.campaignsvc.getData('users').then(res=>{
    //     options = res
    //   })
    // }else if(field =='rule'){
    //   this.campaignsvc.getData('rules').then(res=>{
    //     options = res
    //   })
    // }
    this.campaignsvc.getData(field+'s').then(res=>{
      options = res
      // this.campaignsvc.getCampaignData(this.active_campaign_uuid,field).then(_data=>{
        let _data = (this.active_campaign)? ((field=='did')? this.active_campaign.caller_id_numbers : this.active_campaign[field+'s']) : []
        if(field=='rule'){
          _data=_data.map(r=>r.uuid)
        }
        let dialogRef = this.dialog.open(SelectDialog, {
          // height: '400px',
          width: '300px',
          data: {
            options:  options,
            title: "Select "+field,
            multiple: true,
            key:'uuid',
            display_key: (field=='user')? 'first_name' : 'name',
            data: _data
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result:`, result); 
          if(result){
            let options={did:'caller_id_numbers',
                    user:'users',
                    rule:'rules',
                    number_group:'files'}
            let data:any = {}
            data[options[field]] = result
            this.campaignsvc.update(this.active_campaign_uuid, data, {field: field}).then(res=>{
              this.msgSvc.show("Campaign updated successfully")
            });
          }
        });
      // })
      
    })
    
  }
  ngOnDestroy() {
    this.subscriptionArray.forEach(sb => sb.unsubscribe());
		this.events.publish("toggle-menu",false)
  }
  ngOnInit() {
  }

}
