import { Component, OnInit, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { TyWidget } from '../../../../../../core/_base/layout/models/ty-widget.model';
import { forEach } from '@angular/router/src/utils/collection';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SelectDialog } from '../../../crud';
@Component({
  selector: 'kt-ty-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  private _widgetData: any;
  private timeoutArray:any=[];
  dataAvailable:boolean = true;
  private timersSubscription:Subscription[] = [];
  @Input() widget: TyWidget;
  // @Input() widgetData: any;
  @Input() name: string;
  @Input() theme: any;

  @Input()
  set widgetData(value: any) {
    // this._widgetData = value = [
    //   {
    //     "call.caller_id_number": {
    //       "css_color": "",
    //       "data": "779984144"
    //     },
    //     "call.contact_number": {
    //       "css_color": "",
    //       "data": "039000000"
    //     },
    //     "call.direction": {
    //       "css_color": "",
    //       "data": "outgoing"
    //     },
    //     "call.duration_time": {
    //       "css_color": "",
    //       "data": "00:00:04"
    //     },
    //     "call.extension_username": {
    //       "css_color": "",
    //       "data": "100009"
    //     },
    //     "call.start_at": {
    //       "css_color": "",
    //       "data": "10:57:28"
    //     }
    //   }
    // ]
    // console.log("for", value)
    //////////
    // widgetData/value:{
    //   widget_1_name:...,
    //   widget_2_name:...,
    //    widget_3_name:[{row1},{row2}],//--> table widget. row :{col_1_name:{data, css_color}}
    //   widget_4_name:...,
    // }
    //////////
    if (value !== null && this.name &&  this.widget && this.widget.columns && value[this.name]) {
      
      let _colArray = this.widget.columns.filter(c=>c.type=="timestemp")
      for(let i=0; i<this.timersSubscription.length;i++){
        this.timersSubscription[i].unsubscribe;
      }
      this.timersSubscription = [];
      for(let j=0;j<_colArray.length;j++){
        if(_colArray[j] && _colArray[j].type=="timestemp"){
          let col = _colArray[j];
          for(let i=0;i<value[this.name].length;i++){
            let row = value[this.name][i];
            if(row[col.name]){
              // console.warn("col.name] row[col.name]", col.name,row[col.name])
              this.startTimer(row,
                row[col.name].data, col.name)
            }
            // console.log("for",this.widget.columns, i, value, this.widget.name,col.name)
            
          }
        }
      }
    }
    this._widgetData = value;
    // ////////////////////////////////////////////
    // this._widgetData = {
    //   "eb42f258-f62c-42e6-818d-470835a40e73": [
    //     {
    //       "user.call_incoming_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_incoming_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.fullname": {
    //         "css_color": "",
    //         "data": "11117"
    //       },
    //       "user.last_call": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.last_login": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.spy": {
    //         "css_color": "",
    //         "data": "{:type=>\"url_multi\", :data=>{:data=>\"/api/calls/578768-6768-768-6788?leg_a={{uuid}}&...\", :data_options=>[{:uuid=>\"hjgjgj-hgu-878yuh...\", :name=>\"ext1\"}]}, :name=>\"column name\"}"
    //       },
    //       "user.state": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.status": {
    //         "css_color": "",
    //         "data": ""
    //       }
    //     },
    //     {
    //       "user.call_incoming_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_incoming_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.fullname": {
    //         "css_color": "",
    //         "data": "11119"
    //       },
    //       "user.last_call": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.last_login": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.spy": {
    //         "css_color": "",
    //         "data": "{:type=>\"url_multi\", :data=>{:data=>\"/api/calls/578768-6768-768-6788?leg_a={{uuid}}&...\", :data_options=>[{:uuid=>\"hjgjgj-hgu-878yuh...\", :name=>\"ext1\"}]}, :name=>\"column name\"}"
    //       },
    //       "user.state": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.status": {
    //         "css_color": "",
    //         "data": ""
    //       }
    //     },
    //     {
    //       "user.call_incoming_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_incoming_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_count": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.call_outgoing_duration": {
    //         "css_color": "",
    //         "data": "0"
    //       },
    //       "user.fullname": {
    //         "css_color": "",
    //         "data": "11120"
    //       },
    //       "user.last_call": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.last_login": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.spy": {
    //         "css_color": "",
    //         "data": "{:type=>\"url_multi\", :data=>{:data=>\"/api/calls/578768-6768-768-6788?leg_a={{uuid}}&...\", :data_options=>[{:uuid=>\"hjgjgj-hgu-878yuh...\", :name=>\"ext1\"}]}, :name=>\"column name\"}"
    //       },
    //       "user.state": {
    //         "css_color": "",
    //         "data": ""
    //       },
    //       "user.status": {
    //         "css_color": "",
    //         "data": ""
    //       }
    //     }
    //   ]
    // }
    ////////////////////////////////////////////
  }
  get widgetData() {
    return this._widgetData;
  }
  constructor(private ref:ChangeDetectorRef, public dialog: MatDialog) { }

  startTimer(obj:any, start_time:any=0, prop_name:string){
    // console.log("startTimer", obj, start_time, prop_name)
    if(start_time!=0) {
      start_time = this.stringToSeconds(start_time);
    }
    // console.log("startTimer", obj, start_time, prop_name)
    let hours = 0;
    let seconds = 0;
    let minutes = 0;
    obj[prop_name]['_timer'] = start_time;//Math.floor(new Date().getTime()/1000) -(+start_time)//0 ; //TODO call_start_at timestemp from server
    obj[prop_name]['_timer_string'] = this.secondsToString(obj[prop_name]['_timer'])//'';
    obj.timerVar = interval(1000).pipe(takeWhile(val=>this.dataAvailable)).subscribe(x => {
      obj[prop_name]['_timer']++;
      obj[prop_name]['_timer_string'] = this.secondsToString(obj[prop_name]['_timer'])
      
      this.ref.markForCheck();
    })
    this.timersSubscription.push(obj.timerVar);

  }
  // endTimer(){
  //   this.dataAvailable = false;
  // }
  private stringToSeconds(str:string) {
      var p = str.split(':'),
          s = 0, m = 1;
  
      while (p.length > 0) {
          s += m * parseInt(p.pop(), 10);
          m *= 60;
      }
      return s;
  }
  private secondsToString(sec){
    // console.log("secondsToStringdd",sec)
    let time_string = "";
    var hours   = Math.floor(sec / 3600);
    var minutes = Math.floor((sec - (hours * 3600)) / 60);
    var seconds = sec - (hours * 3600) - (minutes * 60);
    // if(hours>0){
      time_string += (hours>0)? hours.toString()+":" : '00:'
    // }
    time_string += (minutes<10)? "0"+minutes.toString()+":" : minutes.toString()+":";
    time_string += (seconds<10)? "0"+seconds.toString() : seconds.toString();
    return time_string;
  }
  private compareProp(a, b): number {
    // console.log("sortCallback", a,b)
    if (a.data > b.data) {
         return 1;
    }else if (a.data < b.data) {
         return -1;
    }else {
         return 0;
    }
  }
  selectLink(value, field_name: string, column) {
    // ////////////////////////////////
    // if(!value.data || !value.data.data || !value.data.data_options)return;
    // let alert = this.alertCtrl.create({
    //   title: field_name,
    //   message: 'Select '+ field_name,
    //   buttons: [
    //     {
    //       text: 'Cancel'
    //     },
    //     {
    //       text: 'Ok',
    //       handler: (data: any) => {
    //         console.log('Checkbox data:', data);
    //         if(data){
    //           let url = value.data.data.replace('{{uuid}}', data)
    //           this.dashboardSvc.link(url,"post")
    //           .then((data) => {
    //             this.events.publish("app:notifications:success", {
    //               message: 'The task was completed successfully',
    //               title: field_name
    //             });
    //           })
    //           .catch(err=>{
    //             console.error(field_name," ERROR:",err);
    //           })
    //         }
    //       }
    //     }
    //   ]
    // });

    // for (let item of value.data.data_options) {
    //   alert.addInput({
    //     type: 'radio',
    //     label: item['name'],
    //     value: item['uuid']
    //   });
    // }
    // alert.present();
    // this.alert = alert;
    // //////////////////////////////////
    console.log('select link :', value, field_name, column); 
    let dialogRef = this.dialog.open(SelectDialog, {
      // height: '400px',
      width: '300px',
      data: {
        options:  [{name:'Login', value:"login"},{name:'Logout', value:"logout"},{name:'Break', value:"break"}],//value.data.data_options
        title: "Select status",
        multiple: false,
        key:'value'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); 
    });
  }
  ngOnInit() {
  }
}
