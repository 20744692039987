// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
// RxJS
import { catchError, filter, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { HandleRequest } from '../../_base/layout';

@Injectable()
export class AuthEffects {
    @Effect({dispatch: false})
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
            // console.log("TODO login$ effect - add user:action.payload.user", action)
            if(action.payload.user){
                let account  = {...{environment_uuid:""},...action.payload.user}
                account.environment_uuid = (account.environment_uuid)? account.environment_uuid : ((account.environments && account.environments[0] && account.environments[0].uuid)? account.environments[0].uuid:'')
                this.auth.setEnvironments(account.uuid,[account.environment_uuid])//
                this.store.dispatch(new UserLoaded({ user: account }));
            }
            this.store.dispatch(new UserRequested());
        }),
    );

    @Effect({dispatch: false})
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            // console.warn("AuthActionTypes.Logout---------------------------")
            localStorage.removeItem(environment.authTokenKey);
			this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
        })
    );

    @Effect({dispatch: false})
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.authToken);
        })
    );

    @Effect({dispatch: false})
    loadUser$ = this.actions$
    .pipe(
        ofType<UserRequested>(AuthActionTypes.UserRequested),
        withLatestFrom(this.store.pipe(select(isUserLoaded))),
        filter(([action, _isUserLoaded]) => !_isUserLoaded),
        mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
        tap(_user => {
            console.log("AuthActionTypes loadUser$ effect",_user)
            let account = this.jwtHelper.decodeToken(_user.account)
            if (account) {
                account.environment_uuid = (account.environment_uuid)? account.environment_uuid : ((account.environments && account.environments[0] && account.environments[0].uuid)? account.environments[0].uuid:'')
                console.warn("token status loadUser$ effect", account)
                // this.auth.setEnvironments(account.uuid,[account.environment_uuid])//
                this.store.dispatch(new UserLoaded({ user: account }));
                this.store.dispatch(new Login({  authToken: localStorage.getItem(environment.authTokenKey) , user:account}));
            } else {
                this.store.dispatch(new Logout());
            }
        }),
        catchError((error) => of(error))
      );

    @Effect()
    init$: Observable<Action> = defer(() => {
        const userToken = localStorage.getItem(environment.authTokenKey);
        // console.log("TODO fix new Login action", userToken)
        let observableResult = of({type: 'NO_ACTION'});
        if (userToken) {
            // console.log("TODO init$ effect - fix new Login ")
            observableResult = of(new Login({  authToken: userToken , user:null}));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private jwtHelper: JwtHelperService,
        private store: Store<AppState>) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
}
