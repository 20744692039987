import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, } from '@angular/material';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
// Datatable
import { DataTableComponent } from './general/data-table/data-table.component';
// General widgets
import { Widget1Component } from './widget1/widget1.component';
import { Widget4Component } from './widget4/widget4.component';
import { Widget5Component } from './widget5/widget5.component';
import { Widget12Component } from './widget12/widget12.component';
import { Widget14Component } from './widget14/widget14.component';
import { Widget26Component } from './widget26/widget26.component';
import { Timeline2Component } from './timeline2/timeline2.component';
import { CounterComponent } from './ty/counter/counter.component';
import { PieComponent } from './ty/pie/pie.component';
import { LineComponent } from './ty/line/line.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableComponent } from './ty/table/table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { DriverService } from '../../../../core/_base/layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BarComponent } from './ty/bar/bar.component';

@NgModule({
	declarations: [
		//DataTableComponent,
		// Widgets
		// Widget1Component,
		// Widget4Component,
		// Widget5Component,
		// Widget12Component,
		// Widget14Component,
		// Widget26Component,
		// Timeline2Component,
	CounterComponent, TableComponent, PieComponent, LineComponent, BarComponent],
	exports: [
		//DataTableComponent,
		// Widgets
		// Widget1Component,
		// Widget4Component,
		// Widget5Component,
		// Widget12Component,
		// Widget14Component,
		// Widget26Component,
		// Timeline2Component,
		CounterComponent, TableComponent, PieComponent, LineComponent, BarComponent
	],
	imports: [
		CommonModule,
		NgbModule,
		NgxChartsModule,
		FormsModule,
		CoreModule,
		RouterModule,
		NgxDatatableModule,
		MatTooltipModule
		// PerfectScrollbarModule,
		// MatTableModule,
		// MatIconModule,
		// MatButtonModule,
		// MatProgressSpinnerModule,
		// MatPaginatorModule,
		// MatSortModule,
	]
})
export class WidgetModule {
}
