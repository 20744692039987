import { Component, OnInit, Input } from '@angular/core';
import { TyWidget } from '../../../../../../core/_base/layout/models/ty-widget.model';
import { forEach } from '@angular/router/src/utils/collection';
import { MatDialog } from '@angular/material';
import { TableDialog } from '../../../crud';
import { CallsComponent } from '../../../../../pages/calls/calls.component';
import * as shape from 'd3-shape';
@Component({
  selector: 'kt-ty-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {

  view: any[];
  

  // options
  showXAxis = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = false;
  tooltipDisabled = false;
  showText = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = '10%';
  barPadding = 6;
  groupPadding = 20;
  roundDomains = false;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  animations: boolean = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel = true;
  noBarWhenZero = true;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;
  linearCurve= shape.curveMonotoneX;
  colorScheme = {// see https://github.com/swimlane/ngx-charts/blob/master/projects/swimlane/ngx-charts/src/lib/utils/color-sets.ts
    natural:{
      name: 'natural',
      selectable: true,
      group: 'Ordinal',
      domain: [
        '#da0d0d','#dddddd','#2ba02d', '#f88323', '#a5d7c6', '#f2dfa7', '#7794b1','#d89f59',  '#afafaf', '#707160', '#ba9383', '#d9d5c3'
      ]
  },
  cool:{
    name: 'cool',
    selectable: true,
    group: 'Ordinal',
    domain: [
      '#a8385d',
      '#7aa3e5',
      '#a27ea8',
      '#aae3f5',
      '#adcded',
      '#a95963',
      '#8796c0',
      '#7ed3ed',
      '#50abcc',
      '#ad6886'
    ]
  }
};
 
  // line
  showLabels = false;
  explodeSlices = false;
  doughnut = true;
  arcWidth = 0.4;


  
  @Input() widget: TyWidget;
  @Input() widgetData: any;
  @Input() name: string;
  @Input() theme: any;
  @Input() bar: any;
  @Input()
  set select(select: string) {
    this._chartDataMasterLabel = select;
    this.initData();
  }
  _chartDataMasterLabel = '';
  optionSelected;
  chartDataPrimary: {};
  chartDataSecondary: {};
  target: {}[];
  refline=0;
  reflabel = '';

  constructor( public dialog: MatDialog) { }
  setRefline(target,chart_data) {
    console.log(target);
    console.log(chart_data);
    let max = 0;
    if(chart_data.length > 0 && chart_data[0] !== {}) {
      chart_data.forEach(el => {
        el['series'].forEach(el => {
          max = el.value > max ? el.value : max;
        });
      });
      
      this.refline = (this.widget._type.maxValue && this.widget._type.maxValue > max) ? ((target[0].value / this.widget._type.maxValue) * 100) : ((target[0].value / max) * 100);
      this.reflabel = target[0].name;
    }
  }
  selectOption(option) {
    if(this.widget._type.primary_chart) {
      this.chartDataPrimary = this.widget.chart_data[this._chartDataMasterLabel].primary[option];
      if (this.widget._type.secondary_chart) {
        this.chartDataSecondary = this.widget.chart_data[this._chartDataMasterLabel].secondary[option];
      }
    }

    if(this.widget._type.optionsOnly) {
      this.widget.value = this.widget.values[option].value;
      this.widget.subtitle = (this.widget.values[option].subtitle)? this.widget.values[option].subtitle : this.widget.subtitle;
      this.widget['info_title'] = (this.widget.values[option].info_title)? this.widget.values[option].info_title : this.widget['info_title'];
      this.widget['info_subtitle'] = (this.widget.values[option].info_subtitle)? this.widget.values[option].info_subtitle : this.widget['info_subtitle'];
    }
  }

  getLegend(chart:'primary'|'secondary'):any[] {
    let legend = [];
    let fields = [];
    
    // if(this.widget.chart_data[this._chartDataMasterLabel][chart].length > 0 && this.widget.chart_data[this._chartDataMasterLabel][chart][0] !== {}) {
    //   if(this.widget._type.primary_chart === 'line') {
    //     if(this.widget.options) {
    //       fields = this.widget.chart_data[this._chartDataMasterLabel][chart][this.optionSelected].map(v=>v.name);
    //     } else {
    //       fields = this.widget.chart_data[this._chartDataMasterLabel][chart].map(v=>v.name);
    //     }
    //   }
    //   if(this.widget._type.primary_chart === 'grouped-barchart' || this.widget._type.primary_chart === 'target' || this.widget._type.primary_chart === 'target-bar') {
    //     if(this.widget.options) {
    //       fields = this.widget.chart_data[this._chartDataMasterLabel][chart][this.optionSelected][0].series.map(v=>v.name);
    //     } else {
    //       fields = this.widget.chart_data[this._chartDataMasterLabel][chart][0].series.map(v=>v.name);
    //     }
    //   }
    
    if(this.widgetData && this.widgetData[this.name]){
      fields = this.widgetData[this.name].map(obj=>{return obj.name})
      for (let index = 0; index < fields.length; index++) {
        if(fields[index] !== '') {
          legend.push({
            title: fields[index],
            color: this.colorScheme.cool.domain[index]
          });
        }
      }
    }
    return legend;
  }

  initData() {
    if(this.widget.options) {
      this.optionSelected = this.widget.options[0]._selector;
      this.selectOption(this.optionSelected);
    } else {
      if(this.widget._type.primary_chart) {
        // this.chartDataPrimary = this.widget.chart_data[this._chartDataMasterLabel].primary;
      }
    }
    if(this.widget.target) {
      this.target = this.widget.target;
      if(this.widget._type.primary_chart === 'target-bar') {
        this.setRefline(this.target, this.chartDataPrimary);
      }
    }
    // this.widgetData={"3c1601e9-d081-47f3-8622-55494a8f1a54":  [
    //     {
    //         "name": "One",
    //         "value": 5
    //     },
    //     {
    //         "name": "Two",
    //         "value": 2
    //     },
    //     {
    //         "name": "Three",
    //         "value": 9
    //     },
    //     {
    //         "name": "Four",
    //         "value": 7
    //     },
    //     {
    //         "name": "Five",
    //         "value": 4
    //     },
    //     {
    //         "name": "Six",
    //         "value": 3
    //     },
    //     {
    //         "name": "Seven",
    //         "value": 5
    //     }
    // ]}
   
  }
  openTable(widget_name){
    let dialogRef = this.dialog.open(CallsComponent, {
      // height: '400px',
      // width: '300px',
      data: {
        mode:'modal',
        state:widget_name
      }
    });

  }
test:any= 
// [
//   {
//     "name": "Germany",
//     "value": 8940000
//   },
//   {
//     "name": "USA",
//     "value": 5000000
//   }
// ]
[
  {
    "name" : "Sep",
    "series" : [
      {
        "name" : "Driver",
        "value" : 7779
      },
      {
        "name" : "Avarage",
        "value" : 4229
      }
    ]
  },
  
]
testLine:any;
  ngOnInit() {
    this.initData();
    
  }
}
