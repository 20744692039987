// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn, isUserLoaded } from '../_selectors/auth.selectors';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigateByUrl('/auth/login');
                    }
                })
            );
        // console.log("AuthGuard canActivate")
        // let o1: Observable<any> = this.store.pipe(select(isUserLoaded),
        // tap(loggedIn => {
        //     if (!loggedIn) {
        //         console.log("AuthGuard canActivate ERR")
        //         this.router.navigateByUrl('/auth/login');
        //     }
        // }))
        // let o2: Observable<any> = this.store
        // .pipe(
        //     select(isLoggedIn),
        //     tap(loggedIn => {
        //         if (!loggedIn) {
        //             console.log("AuthGuard canActivate ERR")
        //             this.router.navigateByUrl('/auth/login');
        //         }
        //     })
        // );

        // return forkJoin(o1, o2).pipe(
        //     map(([a, b]) => a && b)
        //   );
    }
}
