import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'select-dialog',
    templateUrl: 'select-dialog.component.html',
    styleUrls:['select-dialog.component.scss']
  })
  export class SelectDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SelectDialog>) {
        console.log("SELECT DIALOG data", data)
        if(data.data){
            //data.data = data.data.map(e=>e.uuid) ;
            data.options.map(option=>{
                if(data.data.indexOf(option.uuid)>-1){
                    option.selected = true;
                }
                return option;
            })
        }
    }

    close(){
        this.dialogRef.close();
    }
    send(value){
        console.log("send", value)
        if(!this.data.multiple){
            this.dialogRef.close(value[this.data.key]);
        }else{
            this.dialogRef.close(this.data.options.filter(op=>op.selected).map(option=>{return option[this.data.key]}))
        }
    }
  }