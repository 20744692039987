import { Component, OnInit } from '@angular/core';
import { DriverService, FleetService } from '../../../core/_base/layout';

@Component({
  selector: 'kt-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  fleetMock;
  driversMock;
  constructor(private driverSvc:DriverService,private fleetSvc:FleetService) {
    this.fleetMock = require('../../../../assets/_config/data/fleet.json');
    this.driversMock = require('../../../../assets/_config/data/driver.json');
  }

  ngOnInit() {
  }

  generateMock(driverAmount=10) {
    this.driversMock = this.driverSvc.mockData(driverAmount);
    this.fleetMock = this.fleetSvc.mockData();
    
  }

  uploadSearchIndex() {
    this.driverSvc.uploadSearchIndex(this.driversMock);
    this.fleetSvc.uploadSearchIndex(this.fleetMock);
  }

}
