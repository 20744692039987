import { Events, HandleRequest } from '../../../../core/_base/layout';
import { WebsocketService } from '../../../../core/_base/layout/services/action-cable.service';
import { DashboardService } from '../../../../core/_base/layout/services/dashboard.service';
import { TyFleet, TyAlert } from '../../../../core/_base/layout/models/ty-fleet.model';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FleetService, SearchService, TranslationService } from '../../../../core/_base/layout';
import { Map, GeoJSONSourceRaw, HeatmapPaint, CirclePaint } from 'mapbox-gl';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);
@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers:[DashboardService]
})
export class HomeComponent implements OnInit, OnDestroy {
  widgetData:any={};
  subscriptionArray:Subscription[] = [];
  //////////////
  loadingIndicator: boolean = false;
  reorderable: boolean = false;
  select;
  uuid:string = null;
  type:string = null;
  ws_channel:string  = null;
  fleet: TyFleet;
  dashboard:any[];
  alerts: TyAlert[];
  _alertsOriginal: TyAlert[];
  lang;
  activeTab;
  marker = {
    parking: true,
    moving: true
  };
  segments:any[];
  params:any[];
  //////////////////
  constructor(private dbSvc:DashboardService,
    private route: ActivatedRoute,private events:Events,
    private translate: TranslateService,
    private search: SearchService,
    private ref: ChangeDetectorRef) {
    this.loadingIndicator = true;
    this.select = 'now';
    // const searchSubscription = this.search.alertHits.subscribe((hits) => {
    //   if (hits != null) {
    //     if (hits.length > 0) {
    //       this.alerts = hits;
    //     } else {
    //       this.alerts = [];
    //     }
    //   } else {
    //     this.alerts = this._alertsOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
    // this.subscriptionArray.push(searchSubscription)
    
    // if(this.type!='campaign'){
    //   this.search.focus.next('dashboard');
    //   const searchSubscription = this.search.filter.subscribe(data=>{
    //     console.log("this.search.filter", data)
    //     if(data && this.type!='campaign') {
    //       let _filters:any;
    //       if(data.params){
    //         _filters= data.params
    //       }else{
    //         // this.params = [];
    //       }
    //       // this.filters = _filters;
    //       this.saveParams(data.params_to_save);
    //     }
    //   })
    //   this.subscriptionArray.push(searchSubscription);
    // }
    
  }
  saveParams(params_to_save){
    this.dbSvc.saveParams(this.type,{params:params_to_save}, this.uuid).then((res)=>{
      this.search.status.next('ok')
    },(err)=>{
      this.search.status.next('error')
    }).catch(err=>{console.error(err)})
  }
  ngOnInit() {
    const routeParamMapSubscription = this.route.paramMap.subscribe(params => {
      this.resetWsConection();
      console.log("ngOn paramMap", params)
      this.uuid = params.get('id') || '';
      this.type = params.get('type') || 'live';
      // if(this.type=='live'){
      //   this.events.publish("screen-changed",{name:"dashboard", toggle:false}) 
      // }
      if(this.type!='campaign'){
        this.search.focus.next('dashboard');
        const searchSubscription = this.search.filter.subscribe(data=>{
          console.log("this.search.filter", data)
          if(data && this.type!='campaign') {
            let _filters:any;
            if(data.params){
              _filters= data.params
            }else{
              // this.params = [];
            }
            // this.filters = _filters;
            this.saveParams(data.params_to_save);
          }
        })
        this.subscriptionArray.push(searchSubscription);
      }
      this.dbSvc.get(this.type, this.uuid)//
      .then(widgets => {
        console.log("get", widgets)
          this.dashboard = this.setDashboard(widgets);
          this.setDashboardConnection()
                        .then(res => {
                          // this.status = "active";
                          this.loadingIndicator = false;
                          this.alerts = this._alertsOriginal = widgets.alerts;
                          this.ref.detectChanges();
                        })
                        .catch(err => {
                          console.error(err);
                        });

          this.ref.detectChanges();
      })
      .catch(err => {
        console.error("error-dashboard", err);
      });
      if(this.type!='campaign'){
        this.dbSvc.getSegments(this.type, this.uuid)
        .then((res:any) => {
          this.segments = res;
          this.search.init(this.segments,[])//TODO add params
          this.dbSvc.getParams(this.type, this.uuid)
          .then((res:any) => {
            this.params = res;
            this.search.init(this.segments,this.params)
            this.ref.detectChanges();
          });
        });
      }
      
    });
    this.subscriptionArray.push(routeParamMapSubscription)
  }
  resetWsConection(){
    this.dbSvc.leave(this.ws_channel);
    if(this.widgetDataSubscription)this.widgetDataSubscription.unsubscribe();
    this.ws_channel = '';
  }
  ngOnDestroy(): void {
    this.subscriptionArray.map(sb=>sb.unsubscribe())
    this.resetWsConection()
  }
  private setDashboard(widgets:any[]){
    console.log("set dashboard", widgets)
    for(let i=0; i<widgets.length;i++){
      if(widgets[i].template == 'counter'){
        let columns = widgets[i].columns
        for(let j=0;j<columns.length;j++){
          columns[j]['_gridsize'] = 4;
          columns[j]["_type"] = {
            "counterOnly" : true,
            "counterShow" : true
          }
          columns[j].icon = columns[j].icon.substring(1);
          columns[j]['title'] = this.getColName(columns[j])//columns[j].header_name;
          columns[j]['subtitle'] = columns[j].header_name;
        }
      }else if(widgets[i].template == 'table'){
        let columns = widgets[i].columns
        for(let j=0;j<columns.length;j++){
          columns[j].icon = columns[j].icon.substring(1);
          columns[j].header_name = this.getColName(columns[j])//columns[j].header_name;
          
        }
      }else if(widgets[i].template == 'pie'){
        widgets[i]['_type']= {primary_chart:'pie'}
        
      }else if(widgets[i].template == 'line'){
        widgets[i]['_type']= {primary_chart:'line'}
        
      }else if(widgets[i].template == 'bar'){
        widgets[i]['_type']= {primary_chart:'bar'}
        
      }
      
    }
    console.log("set dashboard end", widgets)
    return widgets;
    
  }
  private getColName(column){
    if(column.header_name){
      return column.header_name;
    }else{
      return this.translate.instant('IDENTITY.FIELDS.'+column.name)
    }
  }
  private widgetDataSubscription:Subscription;
  // get widget data from server to create view
  private setDashboardConnection(): Promise<any> {

    return new Promise(resolve => {
      
    this.ws_channel = this.dbSvc.join(this.type,this.uuid);

      console.warn(this.dbSvc.widgetData);

      this.widgetDataSubscription = this.dbSvc.widgetData.subscribe(
        data => {

          this.widgetData = this.mergeObjs(this.widgetData,data)//{...this.widgetData,...data};
          // console.log("ws this.dbSvc.widgetData",data, this.widgetData)
          this.ref.detectChanges();
        },
        err => {
          console.error(err);

        }
      );
      resolve(true);
      this.subscriptionArray.push(this.widgetDataSubscription)
    });
  }
  private mergeObjs(obj1, obj2){
    var merged = {}
    let keys1 = Object.keys(obj1);
    keys1.forEach(k1 => {
       merged[k1] = obj2[k1] || obj1[k1]; // replace values from 2nd object, if any
    })
    Object.keys(obj2).forEach(k2 => {
       if (!keys1.includes(k2)) merged[k2] = obj2[k2]; // add additional properties from second object, if any
    })
    return merged
  }
  link(value, field_name: string, request_type:string) {
    // let counterData = this._tableData;
    // // console.log(counterData)
    // //console.log(value)
    //   for(let i in counterData){
    //     for(let j=0; j<counterData[i].length; j++){
    //       let row = counterData[i][j];
    //       let conference_mute = row['conference.mute'].data;
    //       if(conference_mute == value.data){
    //         let state = row['conference.member_state'].data;
    //         console.log(state)
    //         if(state == "mute"){
    //           document.getElementById(value.data).className = "fa #fa fa-microphone"
    //           field_name = "conference unmute"
    //         }
    //         else{
    //           document.getElementById(value.data).className = "fa #fa fa-microphone-slash"
    //           field_name = "conference mute"
    //         }
    //       }
    //     }
    //   }
    // this.dbSvc.link(value.data, request_type)
    // .then((data) => {
    //   // this.events.publish("app:notifications:success", {
    //   //   message: field_name+'The task was completed successfully',
    //   //   title: field_name
    //   // });
    // })
    // .catch(err=>{
    //   console.error(field_name," ERROR:",err);
    // })
  }
  selectLink(value, field_name: string, column) {
    ////////////////////////////////
    // if(!value.data || !value.data.data || !value.data.data_options)return;
    // let alert = this.alertCtrl.create({
    //   title: field_name,
    //   message: 'Select '+ field_name,
    //   buttons: [
    //     {
    //       text: 'Cancel'
    //     },
    //     {
    //       text: 'Ok',
    //       handler: (data: any) => {
    //         console.log('Checkbox data:', data);
    //         if(data){
    //           let url = value.data.data.replace('{{uuid}}', data)
    //           this.dashboardSvc.link(url,"post")
    //           .then((data) => {
    //             this.events.publish("app:notifications:success", {
    //               message: 'The task was completed successfully',
    //               title: field_name
    //             });
    //           })
    //           .catch(err=>{
    //             console.error(field_name," ERROR:",err);
    //           })
    //         }
    //       }
    //     }
    //   ]
    // });

    // for (let item of value.data.data_options) {
    //   alert.addInput({
    //     type: 'radio',
    //     label: item['name'],
    //     value: item['uuid']
    //   });
    // }
    // alert.present();
    // this.alert = alert;
    //////////////////////////////////
  }
  getCellClass({ row, column, value }): any {
    let cls = " "
    cls+=(value && value.css_color)? value.css_color : "";
    return cls
  }
}
