import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  QueryList,
  ElementRef,
  ViewChildren
} from '@angular/core';
import {
  Events,
  ReportService,
  SearchService
} from '../../../core/_base/layout';
import {
  Router,
  ActivatedRoute,
  Route
} from '@angular/router';
import {
  TyReport
} from '../../../core/_base/layout/models/ty-report.model';
import { EnvironmentService } from '../../../core/_base/layout/services/environmet.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'kt-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  reports: TyReport[];
  reports$:Observable<TyReport[]>;
  searchQuery:string=''
  _reportsOriginal: TyReport[];
  show_menu:boolean=false
  dataAvaliable:boolean=false;;
  @ViewChildren('reportsElements') private reportsElements: QueryList<ElementRef>;
  constructor(private router:Router, private reportSvc: ReportService, private activeRoute: ActivatedRoute,
              private search: SearchService, private events:Events,
              private ref: ChangeDetectorRef) {
                
    // this.search.focus.next('reports');
    this.loadReports();
    this.events.publish("screen-changed",{name:"reports", toggle:true})
    this.events.subscribe("toggle-menu", (data)=>{
      this.show_menu = data
    })
    // this.search.reportHits.subscribe((hits) => {
    //   if(hits != null) {
    //     if(hits.length > 0) {
    //       this.reports = hits;
    //     } else {
    //       this.reports = []; 
    //     }
    //   } else {
    //     this.reports = this._reportsOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
  }
  searshReports(query){
    this.reports$ = of(this.reports).pipe(
      // delay(2000),
      map(c => {
        // if filter is empty return all reports
        if (query=='') {
          return c;
        }
    
        // search for specific reports_
        const filteredReports: TyReport[] = [];
    
        c.filter(function(report) {
          if (report.name.toLowerCase().includes(query.toLowerCase())) {
            filteredReports.push(report);
          }
        });
    
        return filteredReports;
      })
    );
  }
  loadReports(options:{reloadChild?:boolean} = {reloadChild:false}){
    this.reportSvc.get().then((reports) => {
      this.reports = this._reportsOriginal = reports;
      this.reports$ = of(this.reports)
      this.dataAvaliable = true;
      this.ref.detectChanges();
      if(this.reports && this.reports.length>0){
        let url = this.activeRoute.snapshot['_routerState'].url
        let urlArray = url.split("/")
        if(urlArray.length<5 || options.reloadChild){
          ((this.reportsElements.toArray())[0].nativeElement as HTMLElement).click()
        }
      }
      
    });
  }
  closeMenu(){
    this.events.publish("toggle-menu", false)
    this.show_menu=false;
  }
  ngOnDestroy() {
		this.events.publish("toggle-menu",false)
  }
  ngOnInit() {
  }

}
