import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  QueryList,
  ElementRef,
  ViewChildren
} from '@angular/core';
import {
  UserService,
  SearchService
} from '../../../core/_base/layout';
import {
  Router,
  ActivatedRoute,
  Route
} from '@angular/router';
import {
  TyUser
} from '../../../core/_base/layout/models/ty-user.model';
import { EnvironmentService } from '../../../core/_base/layout/services/environmet.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'kt-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  users: TyUser[];
  users$:Observable<TyUser[]>;
  searchQuery:string=''
  _usersOriginal: TyUser[];
  dataAvaliable:boolean=false;;
  @ViewChildren('usersElements') private usersElements: QueryList<ElementRef>;
  constructor(private router:Router, private usersvc: UserService, private activeRoute: ActivatedRoute,
              private search: SearchService,
              private ref: ChangeDetectorRef) {
                
    this.search.focus.next('users');
    this.loadUsers();
    
    // this.search.userHits.subscribe((hits) => {
    //   if(hits != null) {
    //     if(hits.length > 0) {
    //       this.users = hits;
    //     } else {
    //       this.users = []; 
    //     }
    //   } else {
    //     this.users = this._usersOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
  }
  searshUsers(query){
    this.users$ = of(this.users).pipe(
      // delay(2000),
      map(c => {
        // if filter is empty return all users
        if (query=='') {
          return c;
        }
    
        // search for specific users_
        const filteredUsers: TyUser[] = [];
    
        c.filter(function(user) {
          if ((user.first_name + ' '+ user.last_name +' '+ user.username).toLowerCase().includes(query.toLowerCase())) {
            filteredUsers.push(user);
          }
        });
    
        return filteredUsers;
      })
    );
  }
  loadUsers(options:{reloadChild?:boolean} = {reloadChild:false}){
    this.usersvc.get().then((users) => {
      this.users = this._usersOriginal = users;
      this.users$ = of(this.users)
      this.dataAvaliable = true;
      this.ref.detectChanges();
      if(this.users ){
        let url = this.activeRoute.snapshot['_routerState'].url
        let urlArray = url.split("/")
        if(urlArray.length<5 || options.reloadChild){
          ((this.usersElements.toArray())[0].nativeElement as HTMLElement).click()
        }
      }
      
    });
  }
  ngOnDestroy() {
    
  }
  ngOnInit() {
  }

}
