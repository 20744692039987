// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Subscription, Observable } from 'rxjs';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService, TranslationService, SearchService, FleetService, HandleRequest, Events } from '../../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '../../../../core/_config/demo3/layout.config';
import { MenuConfig } from '../../../../core/_config/demo3/menu.config';
import { PageConfig } from '../../../../core/_config/demo3/page.config';
// User permissions
import { NgxPermissionsService } from 'ngx-permissions';
import { Permission, currentUserPermissions, currentUser, Logout, AuthService} from '../../../../core/auth';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public constructor
	selfLayout: string;
	asideDisplay: boolean;
	asideSecondary: boolean;
	subheaderDisplay: boolean;

	isRTL: boolean = false;
	toggle_menu:boolean = false;
	current_screen:{screen_name:string, toggle:boolean}= {screen_name:'',toggle:false };
	menuItems = [
		{
			title: 'HOME.MENU',
			icon: 'fa fa-tachometer',
			page: 'home'
		},
		// {
		// 	title: 'USERS.MENU',
		// 	icon: 'fa fa-envelope',//'fa fa-users',
		// 	page: 'inboxes'
		// },
		{
			title: 'REPORTS.MENU',
			icon: 'fa fa-bar-chart',
			page: 'reports'
		},
		{
			title: 'CAMPAIGNS.MENU',
			icon: 'fa fa-paper-plane',
			page: 'campaigns'
		},
		{
			title: 'CALLS.MENU',
			icon: 'fa fa-phone',
			page: 'calls'
		},
		// {
		// 	title: 'DRIVERS.MENU',
		// 	icon: 'drivers',
		// 	page: 'drivers'
		// },
		// {
		// 	title: 'FLEET.MENU',
		// 	icon: 'fleet',
		// 	page: 'fleet'
		// },
		// {
		// 	title: 'HYBRID.MENU',
		// 	icon: 'fleet',
		// 	page: 'hybrid'
		// },
		// {
		// 	title: 'SETTINGS.MENU',
		// 	icon: 'settings',
		// 	page: 'settings'
		// }
	];

	searchQuery:string;
	searchFocus:string;
	searchResultsCount:number;

	total_alerts:number;
	lang;
	customer_data:any;
	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private currentUserPermissions$: Observable<Permission[]>;
	private currentUser$:Observable<any>;//Subscription////acount
	private account:any={}//type Account
	private heLang = require('../../../../../assets/_config/i18n/he.json');
	private enLang = require('../../../../../assets/_config/i18n/en.json');


	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param menuConfigService: MenuConfifService
	 * @param pageConfigService: PageConfigService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService,
		private store: Store<AppState>,
		private permissionsService: NgxPermissionsService,
		private translationService: TranslationService,
		private route: ActivatedRoute,
		private router: Router,
		private search: SearchService,
		private alerts_service: FleetService,private handleRequest:HandleRequest,
		private auth:AuthService,
		private ref: ChangeDetectorRef,
		private events:Events,
		iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

		iconRegistry.addSvgIcon(
			'logout',
			sanitizer.bypassSecurityTrustResourceUrl('assets/media/icons/logout.svg'));

		this.loadRolesWithPermissions();
		this.loadUser();
		// register configs by demos
		const _layoutConfig = new LayoutConfig().configs;

		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		this.menuConfigService.loadConfigs(new MenuConfig().configs);
		this.pageConfigService.loadConfigs(new PageConfig().configs);

		// setup element classes
		const _layoutServiceConfig = this.layoutConfigService.getConfig();

		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const layoutSubdscription = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(layoutSubdscription);

		this.alerts_service.getNumOfAlerts().subscribe((total) => {
			this.total_alerts = total;
    	});

		this.events.subscribe("screen-changed", (screen)=>{
			this.current_screen.screen_name = screen.name;
			this.current_screen.toggle = screen.toggle;
		})
		this.events.subscribe("toggle-menu", (data)=>{
			this.toggle_menu = data
		})
	}
	setLang(lang){
		console.log("setLang", lang)
		const dir = require('../../../../../assets/_config/i18n/'+lang+'.json').dir;
		this.translationService.setLanguage(lang, dir);
		if(dir === 'rtl') {
			this.isRTL = true;
		}else{
			this.isRTL = false;
		}
		this.lang = lang;
		console.log("setLang", lang)
		this.ref.detectChanges()
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.customer_data = this.handleRequest.getCustomerData();
		// document.documentElement.style.setProperty('--color-toyota-red', this.customer_data.logo_color);
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.asideSecondary = objectPath.get(config, 'aside-secondary.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');

		// let the layout type change
		const layoutConfigSubscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(layoutConfigSubscription);

		this.route.params
		.subscribe(params=> {
			console.log(params.lang);
			const dir = require('../../../../../assets/_config/i18n/'+params.lang+'.json').dir;
			console.log(dir);
			this.translationService.setLanguage(params.lang, dir);
			if(dir === 'rtl') {
				this.isRTL = true;
			}
		});
		this.lang = this.translationService.getSelectedLanguage();

		this.router.events
		.pipe(
			// filter(event => event instanceof NavigationEnd),
			// map(() => this.route.root.firstChild.firstChild.firstChild.routeConfig.path)
		)
		.subscribe((event)=> {
			if(event instanceof NavigationStart){
				// console.log("va-- screen", event)
				this.search.clear();
			}
			// this.search.focus.next(screen);
		});

		this.search.focus.subscribe((focus)=> {
			console.log('va-- focus',focus);

			if(focus !== 'campaignNumbers' && focus !== 'drivers' && focus !== 'alerts' && focus !== 'calls' && focus !== 'dashboard' && focus !== 'campaign'  && focus !== 'report') {
				this.searchFocus = '';
				this.clearSearch();
			} else {
				this.searchFocus = focus;
			}

			this.ref.detectChanges();
		});
	}
	toggleMenu(){
		this.toggle_menu = !this.toggle_menu;
		this.events.publish("toggle-menu", this.toggle_menu)
	}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
		this.events.destroy("screen-changed")
	}

	clearSearch() {
		this.searchQuery = '';
		this.searchResultsCount = null;
		this.search.clear();
	}
	runSearch(q) {
		console.log(q);

		if(q) {
			if(this.searchFocus === 'drivers') {
				this.search.drivers(q).then((hits)=> {
					console.log(hits.hits.length);
					this.searchResultsCount = hits.hits.length;
					this.ref.detectChanges();
				});
			}
			if(this.searchFocus === 'alerts') {
				this.search.alerts(q).then((hits)=> {
					this.searchResultsCount = hits.hits.length;
					this.ref.detectChanges();
				});
			}
		}
	}

	/**
	 * NGX Permissions, init roles
	 */
	loadRolesWithPermissions() {
		this.currentUserPermissions$ = this.store.pipe(select(currentUserPermissions));
		const subscr = this.currentUserPermissions$.subscribe(res => {
			if (!res || res.length === 0) {
				return;
			}

			this.permissionsService.flushPermissions();
			res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
		});
		this.unsubscribe.push(subscr);
	}
	loadUser(){
		this.currentUser$ = this.store.pipe(select(currentUser))
		// .pipe(
		// 	tap(account => {
		// 		// console.log("loadUser currentUser tap account", account)
		// 		this.account = account;
		//  })).subscribe();
		//  this.unsubscribe.push(this.currentUser$);
		console.log("----------------------loadUser currentUser", this.currentUser$)
		const subscr = this.currentUserPermissions$.subscribe(res => {
			if (!res || res.length === 0) {
				return;
			}

			this.permissionsService.flushPermissions();
			res.forEach((pm: Permission) => this.permissionsService.addPermission(pm.name));
		});
		this.unsubscribe.push(subscr);
	}
	logout(){
		this.store.dispatch(new Logout());
	}
	setEnvironment(env){
		this.currentUser$.subscribe(res=>{
			this.auth.setEnvironments(res.uuid, [env])
		})
	}
}
