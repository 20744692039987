import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DriverService, SearchService } from '../../../../core/_base/layout';
import { TyDriver } from '../../../../core/_base/layout/models/ty-driver.model';
import { CirclePaint, GeoJSONSource, Source, GeoJSONSourceRaw, SymbolLayout } from 'mapbox-gl';
import { TranslationService } from '../../../../core/_base/layout/services/translation.service';
const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);
@Component({
  selector: 'kt-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {
  driver: TyDriver;
  activeTab;

  paint: CirclePaint = {
    'circle-radius': 18,
    'circle-color': '#da0d0d',
    'circle-stroke-width':2,
    'circle-stroke-color':'#ffffff'
  };

  layout: SymbolLayout = {
    'text-field': '{title}',
    'text-font': [
      'DIN Offc Pro Medium',
      'Arial Unicode MS Bold'
    ],
    'text-size': 12
  };

  source:GeoJSONSourceRaw;
  mapCenter;
  mapZoom = [9];

  objectKeys = Object.keys;

  // datatable
  loadingIndicator: boolean = false;
  reorderable: boolean = false;
  select;
  currentState:string="";
  write = false;
  constructor(private route: ActivatedRoute, private driverSvc: DriverService, private translateSvc:TranslationService) {
    this.select = 'one_month';
  }
  save() {
    this.currentState = 'processing';
    this.driverSvc.setDriver(this.driver);
    ////////////
    let interval = setInterval(() => {
        this.currentState = 'completed';
        clearInterval(interval);
        interval = setInterval(() => {
            this.currentState = '';
            clearInterval(interval);
        }, 1500);
    }, 1000);
    ///////////
  }
  toggleWrite() {
    this.write = !this.write;
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);

      this.activeTab = params.tab;
    });
    this.route.paramMap.subscribe(params => {


      this.driverSvc.getOne(params.get('id')).subscribe((driver)=> {
        if(driver) {
          this.driver = driver;

          this.mapCenter = this.driver.center_of_life.center;
          this.mapZoom = [this.driver.center_of_life.zoom];
          this.source = {
            type: 'geojson',
            data: this.driver.center_of_life.location
          };
        }
      });
    });


  }
  translationExists(key: string): boolean {
    return this.translateSvc.hasTranslation(key);
  }
  capitalize(s) {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  statusRows(statuses: []) {
    let rows = [];
    let row = [];
    for (let index = 0; index < statuses.length; index++) {
      row.push(statuses[index]);
      if ((index + 1) % 3 === 0) {
        rows.push(row);
        row = [];
      }
    }
    return rows;
  }
}
