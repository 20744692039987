// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './base/base.component';
import { ErrorPageComponent } from './content/error-page/error-page.component';
// Auth
import { AuthGuard } from '../../../core/auth';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { DriversComponent } from '../../pages/drivers/drivers.component';
import { AuthComponent } from '../../pages/auth/auth.component';
import { DriverComponent } from '../../pages/drivers/driver/driver.component';
import { HomeComponent } from '../../pages/home/dashboard/home.component';
import { SettingsComponent } from '../../pages/settings/settings.component';
import { FleetComponent } from '../../pages/fleet/fleet.component';
import { HybridComponent } from '../../pages/hybrid/hybrid.component';
import { ReportsComponent } from '../../pages/reports/reports.component';
import { ReportComponent } from '../../pages/reports/report/report.component';
import { CampaignsComponent } from '../../pages/campaigns/campaigns.component';
import { UsersComponent } from '../../pages/users/users.component';
import { CallsComponent } from '../../pages/calls/calls.component';
import { CampaignNumbersComponent } from '../../pages/campaigns/numbers/campaign-numbers.component';
import { DashboardsComponent } from '../../pages/home/dashboards.component';

const routes: Routes = [
	// {path:':lang/login',component: LoginComponent},
	// {path:'en/login',component: LoginComponent},
	{
		path: ':lang',
		children: [
			{
				path:'',
				//component: AuthComponent,
				// loadChildren: '../../pages/auth/auth.module#AuthModule'//'app/your.module#YourModule'
				loadChildren: () => import('../../pages/auth/auth.module').then(m => m.AuthModule)
			},
			{
				path:'app',
				component: BaseComponent,
				canActivate: [AuthGuard],
				children: [
					{
						path: 'drivers',
						component: DriversComponent,
						children: [
							{ path: ':id', component: DriverComponent },
						]
					},
					{
						path: 'home',
						component: DashboardsComponent,
						children: [
							{ path: ':id', component: HomeComponent },
						]
					},
					{
						path: 'reports',
						component: ReportsComponent,
						children: [
							{ path: ':id', component: ReportComponent },
						]
					},
					{
						path: 'campaigns',
						component: CampaignsComponent,
						children: [
							{ path: ':id/dashboard/:type', component: HomeComponent },
							{ path: ':id/numbers', component: CampaignNumbersComponent },
						]
					},
					{
						path: 'inboxes',
						component: UsersComponent,
						children: [
							{ path: ':id/dashboard/:type', component: HomeComponent },
						]
					},
					{
						path: 'calls',
						component: CallsComponent,
					},
					// {
					// 	path: 'home',
					// 	component: HomeComponent
					// },
					{
						path: 'settings',
						component: SettingsComponent
					},
					{
						path: 'fleet',
						component: FleetComponent
					},
					{
						path: 'hybrid',
						component: HybridComponent
					},
					{
						path: 'error/403',
						component: ErrorPageComponent,
						data: {
							'type': 'error-v6',
							'code': 403,
							'title': '403... Access forbidden',
							'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
						}
					},
					{path: 'error/:type', component: ErrorPageComponent},
					{path: '', redirectTo: 'home', pathMatch: 'full'},
				]
			},
			{path: '', redirectTo: '/auth/login', pathMatch: 'full'},
			{path: '**', redirectTo: '/auth/login', pathMatch: 'full'}
		],
		
	},
	{path:'',redirectTo:'/auth/login', pathMatch: 'full'},
	{path: '**', redirectTo: '/auth/login', pathMatch: 'full'}

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PagesRoutingModule {
}
