import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  QueryList,
  ElementRef,
  ViewChildren,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
  Inject,
  Optional
} from '@angular/core';
// import dayjs, { Dayjs } from 'dayjs/esm';
import {
  CampaignService,
  MessageService,
  SearchService
} from '../../../../core/_base/layout';
import {
  Router,
  ActivatedRoute,
  Route
} from '@angular/router';
import { Location } from '@angular/common';
import {
  TyCampaign,
  TyCampaignNumber
} from '../../../../core/_base/layout/models/ty-campaign.model';
import _moment from "moment";
import { BaseComponent } from '../../../themes/demo3/base/base.component';
import { EnvironmentService } from '../../../../core/_base/layout/services/environmet.service';
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog, MatMenuTrigger, MAT_DIALOG_DATA } from '@angular/material';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SelectDialog } from '../../../partials/content/crud';

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
  order_by: string = "";
  order_type:string = "";
}


@Component({
  selector: 'kt-campaign-numbers',
  templateUrl: './campaign-numbers.component.html',
  styleUrls: ['./campaign-numbers.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CampaignNumbersComponent implements OnInit, OnDestroy {
  // selected:any =  {
  //   startDate: dayjs().subtract(1, 'days').set('hours', 0).set('minutes', 0),
  //   endDate: dayjs().subtract(1, 'days').set('hours', 23).set('minutes', 59)
  // };
  uuid:string;
  active_campaign:TyCampaign;
  number_groups_array:any[]=[];
  campaignNumbers: TyCampaignNumber[];
  segments:any[]=[];
  params:any[]=[];
  _campaignNumbersOriginal: TyCampaignNumber[];
  // columns:any = []//:TyCampaignNumberColumn[] = [];
  // @ViewChild('selecetdHeaderTmp') public selecetdHeaderTmp: TemplateRef<any>;
  // @ViewChild('numberTmp') public numberTmp: TemplateRef<any>;
  // @ViewChild('rowTmp') public rowTmp: TemplateRef<any>;
  // @ViewChild('linkRowTmp') public linkRowTmp: TemplateRef<any>;
  // @ViewChild('rowDateTmp') public rowDateTmp: TemplateRef<any>;
  // @ViewChild('subRowTmp') public subRowTmp: TemplateRef<any>;
  // @ViewChild('tooltipRowTmp') public tooltipRowTmp: TemplateRef<any>;
  @ViewChild('table') table;
  SelectionType = SelectionType;
  filters:any = {};
  default_filters:any={};
  period:string ;
  totalElements: number;
  pageNumber: number;
  cache: any = {};
  isLoading = 0;
  reorderable: boolean = false;
  pagination = new Page();
  pageCache: any = {};
  reset: boolean = false;
  isFiltered = false;
  totalCount: number;
  selected = [];
  _batchAction = "uudis";
  isTotalSelected = false;
  // columnOptions:any = [];
  showFilter:boolean = false;
  showSettings:boolean = false;
  private _filterSelectField = [];
  private _savedSegments = [];
  private _savedQuery:any;
  private tags = [];
  number_group_name:string="";
  alert:any;
  modal:any;
  countries:any;
  appDirection:string = 'ltr'
  first_time_env_changed:boolean = true;
  private unsubscribe: Subscription[] = [];
  mode:string=''
  ///////audio player options
  msaapDisplayTitle = true;
  msaapDisplayPlayList = false;
  msaapDisplayVolumeControls = true;
  msaapDisplayRepeatControls = true;
  msaapDisablePositionSlider = true;
  //////////
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,private location: Location, private jwtHalper:JwtHelperService, private router:Router, 
              private campaignSvc: CampaignService, private activeRoute: ActivatedRoute,
              @Optional() @Inject(BaseComponent) private base,public dialog: MatDialog,
              private search: SearchService, private envSvc:EnvironmentService,
              private ref: ChangeDetectorRef, private msgSvc:MessageService) {
    this.pageNumber = 0;
    this.pagination.pageNumber = 0;  
    this.period = 'today';
    this.default_filters = {search:{"created_at": this.periodToTime(this.period)}} ;
    this.filters = {search:{"created_at": this.periodToTime(this.period)}}         
    
    this.mode = this.campaignSvc.getCampaignNumbersMode()
    this.uuid =  this.activeRoute.snapshot.paramMap.get('id')
    this.appDirection = (this.base && this.base.isRTL)? 'rtl':'ltr';
    this.active_campaign =  this.campaignSvc.getActiveCampaign(this.uuid)
    if(!this.active_campaign){
      this.campaignSvc.getByUuid(this.uuid).toPromise().then(
        campaign=>{
          this.active_campaign = campaign;
          this.campaignSvc.setActiveCampaign(campaign)
          
          this.ref.detectChanges();
        },
        err=>{
          
        }
      )
    }
    console.log("TABLE DIALOG data---------------------", data)       
    
    if(!this.data){
      this.search.focus.next('campaignNumbers');
    this.campaignSvc.getSegments(this.uuid)
      .then((res:any) => {
        this.segments = res;

        this.campaignSvc.getParams(this.uuid)
        .then((res:any) => {
          console.log("this params ",this.params)
          this.params = [...res, ...this.params];
          this.search.init(this.segments,this.params)
          this.ref.detectChanges();
        });
    });
    

    const searchSubscription = this.search.filter.subscribe(data=>{
      console.log("this.search.filter", data)
      if(data) {
        let _filters:any;
        if(data.params){
          _filters= {search:{...this.default_filters.search,...data.params.search}};
          delete data.params.search;
          _filters = {..._filters,...data.params}
          if(data.period){
            this.period = data.period;
          }else if(data.order_by){
            console.log("if(data.order_by)")
            _filters.order_by = data.order_by
            console.log("if(data.order_by)", this.filters)
          }
        }else{
          this.params = [];
        }
        this.filters = _filters;
        this.saveParamsAndReload(_filters, data.params_to_save);
        // this.resetTable(_filters)
      }
    })
    this.unsubscribe.push(searchSubscription);

    }
    
    const envSubscription = this.envSvc.isChanched.subscribe(data=>{
      console.log("this.envSvc.isChanched", data)
      if(data && !this.first_time_env_changed) this.resetTable(this.filters);
      this.first_time_env_changed = false;
    })
    this.unsubscribe.push(envSubscription);


    const routeQueryParamsSubscription = this.activeRoute.queryParams.subscribe(params => {
      console.log("queryParams",params);
      this.uuid =  this.activeRoute.snapshot.paramMap.get('id')
      if(params.search){
        try{
          let filters = JSON.parse(params.search)
          let _url_paramsArray = [];
          if(filters.created_at && filters.created_at.indexOf('-')>-1){
            let data = filters.created_at.split('-');
            console.log("queryParams switch",filters,data, data[0], _moment().startOf('day').unix(), _moment().startOf('day').unix()==data[0]);
            // if(data[0] == _moment().startOf('day').unix() && data[1] == _moment().endOf('day').unix()) {

            // }else 
            if(data[0] == _moment().startOf('week').unix() && data[1] == _moment().endOf('week').unix()){
              this.period = 'week';
            }else if(data[0] == _moment().startOf('month').unix() && data[1] == _moment().endOf('month').unix()){
              this.period = 'month';
            }else if(data[0] == _moment().startOf('year').unix() && data[1] == _moment().endOf('year').unix()){
              this.period = 'year';
            }else{//there is no support for custom date -- change to default period
              this.period = 'today';
              filters.created_at = this.periodToTime(this.period);
            }
            // _url_paramsArray.push(filters.created_at)
          }
          
          for(let p in filters){
            console.warn("queryParams try p of filters",p);
            if(p!="created_at"){
              let _operator = Object.keys(filters[p])[0]
              let _url_param = {field: p, operator:_operator, value: filters[p][_operator]}
              // _url_paramsArray.push(_url_param)
              this.params.push(_url_param)
            }
          }
          // filters = _url_paramsArray;

          this.filters = {...this.filters,...{search:filters}};
          console.log("queryParams try",params,_url_paramsArray, filters, this.filters);
        }
        catch{
          console.log("queryParams catch",params);
        }
      }

    });
    this.unsubscribe.push(routeQueryParamsSubscription);

    // this.campaignSvc.get().then((calls) => {
    //   this.calls = this._callsOriginal = calls;
    //   this.ref.detectChanges();
    // });

    // this.search.callHits.subscribe((hits) => {
    //   if(hits != null) {
    //     if(hits.length > 0) {
    //       this.calls = hits;
    //     } else {
    //       this.calls = []; 
    //     }
    //   } else {
    //     this.calls = this._callsOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
  }
  addNumbers(){
    console.log('add numbers :'); 
    this.campaignSvc.getNumberGroups().then(res=>{
        this.campaignSvc.getCampaignData(this.uuid,'number_group').then(_data=>{
          this.number_groups_array = res;
          let dialogRef = this.dialog.open(SelectDialog, {
            // height: '400px',
            width: '300px',
            data: {
              options:  res,
              title: "Select number group",
              multiple: true,
              key:'uuid',
              display_key: 'name',
              data: _data
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result:`, result); 
            if(result){
              let options={did:'caller_id_numbers',
                    user:'users',
                    rule:'rules',
                    number_group:'files'}
            let data:any = {}
            data[options['number_group']] = result
              this.campaignSvc.update(this.uuid, data, {field: 'number_group'}).then(res=>{
                this.msgSvc.show("Campaign updated successfully")
              });
            }
          });
        }
      )
    })
    
  }
  filter(col,value){
    console.log("filter",this.campaignSvc.getSegmentName(col.field),col,value)
    if(!this.data)this.search.addParam(this.campaignSvc.getSegmentName(col.field), value)
  }
  saveParamsAndReload(filters, params_to_save){
    this.resetTable(filters);
    this.campaignSvc.saveCampaignNumbersParams(this.uuid, {params:params_to_save}).subscribe((res)=>{
    },(err)=>{
      // this.search.status.next('error')
    })
  }
  loadAudio(row){
    row.showAudio = true;
  }
  audioMenuOpened:MatMenuTrigger ;
  audioOpened(trigger:MatMenuTrigger){
    if(this.audioMenuOpened) this.audioMenuOpened.closeMenu();
    this.audioMenuOpened = trigger
  }
  ngOnDestroy(): void { 
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
  reload():void{
    this.resetTable(this.filters)
  }
  ngOnInit() {
    // this.campaignSvc.getColumns().subscribe((res)=>{
    //   this.columns = res;
    //   this.columnOptions = this.columns;
    //   for(let i=0; i<this.columnOptions.length;i++){
    //     switch (this.columnOptions[i].name) {
    //       case "Date Created":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case "Direction":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case "Duration":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case 'Cause':
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case 'User':
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case 'Disposition':
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case "Number":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case "Billing Duration":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       case "CID":
    //         this.columnOptions[i].selected = true;
    //         break;
    //       default:
    //         this.columnOptions[i].selected = false;
    //         break;
    //     }
    //     switch (this.columnOptions[i].type) {
    //       case "date":
    //         this.columnOptions[i].cellTemplate = this.rowDateTmp;
    //         break;
    //       case "link":
    //         this.columnOptions[i].cellTemplate = this.linkRowTmp;
    //         break;
    //       case "sub_object":
    //         this.columnOptions[i].cellTemplate = this.subRowTmp;
    //         break;
    //       case 'tooltip_object':
    //         this.columnOptions[i].cellTemplate = this.tooltipRowTmp;
    //         break;
    //       case 'sub_object_link':
    //         this.columnOptions[i].cellTemplate = this.subRowTmp;
    //         break;
    //       case 'string':
    //         this.columnOptions[i].cellTemplate = this.rowTmp;
    //         break;
    //       default:
    //         break;
    //     }
    //   }
    // })
    


    // this.router.navigate(['/:id', this.calls[0]._id]);
  }
  played(event: Plyr.PlyrEvent,{i}) {
    console.log('played', event, i);
  }
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    this.pagination.order_type = sortInfo.sorts[0].dir;
    this.pagination.order_by = sortInfo.sorts[0].prop;
    this.resetTable();
  }

  resetTable(_filters?) {
    
    this.isFiltered = false;

    console.log("resetTable", _filters);

    this.pageCache = {};
    this.campaignNumbers = null;
    // this.pagination.pageNumber = 0;
    this.isLoading=1;
    this._savedQuery = null;
    this._savedSegments = []
    let filters = _filters? _filters : this.default_filters ;//= {};
    if(this.pagination.order_by) filters['order_by'] = this.pagination.order_by;
    if(this.pagination.order_by) filters['order_type'] = this.pagination.order_type;
    filters['per_page'] = this.pagination.size;
    this.ref.markForCheck();
    this.campaignSvc.getPage(this.uuid,1, filters)
      .subscribe((page:any) => {
        this.isFiltered = false;
        let total: number = Number.parseInt(page.headers.get('x-total'));
        this.pagination.totalElements = total;
        this.totalCount = total;
        this.pagination.totalPages = Math.ceil(total / this.pagination.size);
        // create array to store data if missing
        // length should be total count
        this.campaignNumbers = new Array<TyCampaignNumber>(this.totalCount || 0);


        const rows = [...this.campaignNumbers];
        // insert rows into new position
        rows.splice(0, page.body.length, ...page.body);
        // set rows to our new rows
        this.campaignNumbers = rows.map(obj=>{
          obj.audioSrc = ( obj.recording && obj.recording.url)? [{src: obj.recording.url,type:'audio/mp3'} ] : null
          return obj;
        })
        this.reset = true;
        this.isLoading--;
        this.ref.markForCheck();
        this.table.bodyComponent.updateOffsetY(0)//this.table.offset = 0;

        // let filterString = encodeURIComponent(JSON.stringify(filters['search']))//this.jwtHalper.urlBase64Decode(JSON.stringify(filters))
        const url = this.router.createUrlTree([], {relativeTo: this.activeRoute, queryParams: {search: JSON.stringify(filters['search'])}}).toString()// + filterString
        this.location.go(url);
        console.log("params change url");
        if(!this.data)this.search.status.next('ok')
      },(err)=>{
        this.campaignNumbers = new Array<TyCampaignNumber>(0);
        if(!this.data)this.search.status.next('error')
      })
      // .catch((error) => {
      //   console.error(error);
      // });
  }
  /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    console.log("setPage test", pageInfo, this.pagination,  this.pageCache,this.pageCache[pageInfo.offset]);
    
    this.pagination.pageNumber = pageInfo.offset;
    this.pagination.size = pageInfo.pageSize;

    // cache results
    if (this.pageCache[pageInfo.offset]) {
      return;
    } else {
      this.pageCache[pageInfo.offset] = true;
    }
    this.isLoading++;
    console.warn("setPage test load page:", this.isLoading);
    let filters:any = this.filters//{};
    if(this.pagination.order_by) filters['order_by'] = this.pagination.order_by;
    if(this.pagination.order_by) filters['order_type'] = this.pagination.order_type;
    filters['per_page'] = this.pagination.size;
    // this.ref.markForCheck();
    this.campaignSvc.getPage(this.uuid, pageInfo.offset + 1, filters)
      .subscribe((page) => {
        console.log("setPage this.numbers :", page);
        let total: number = Number.parseInt(page.headers.get('x-total'));
        this.pagination.totalElements = total;
        this.totalCount = total;
        this.pagination.totalPages = Math.ceil(total / this.pagination.size);

        // create array to store data if missing
        if (!this.campaignNumbers) {
          // length should be total count
          this.campaignNumbers = new Array<TyCampaignNumber>(this.totalCount || 0);
        }
        console.log("setPage this.numbers :", this.campaignNumbers ,"total",this.totalCount, this.campaignNumbers.length);
        // calc start
        const start = pageInfo.offset * this.pagination.size;
        console.log("setPage start:",start,"total:" ,this.totalCount,"setPage pageInfo.offset :", pageInfo.offset ,"setPage this.pagination.size:",this.pagination.size);
        // copy rows
        const rows = [...this.campaignNumbers];
        // insert rows into new position
        rows.splice(start, page.body.length, ...page.body);
        // set rows to our new rows
        this.campaignNumbers = rows.map(obj=>{
          obj.audioSrc = (obj.recording && obj.recording.url)? [{src: obj.recording.url,type:'audio/mp3'} ] : null
          return obj;
        });
        //////////////////////
        //if loading last page --> load also one page before the last
        if(pageInfo.offset!=0 && this.pagination.totalPages && pageInfo.offset==this.pagination.totalPages-1 && !this.pageCache[this.pagination.totalPages-2]){
          let _filters:any = {};
          this.isLoading++;
          if(this.pagination.order_by) _filters['order_by'] = this.pagination.order_by;
          if(this.pagination.order_by) _filters['order_type'] = this.pagination.order_type;
          _filters.per_page = this.pagination.size;
          this.campaignSvc.getPage(this.uuid,this.pagination.totalPages- 1, _filters)
          .subscribe((page:any) => {
            // calc start
            const _start = (this.pagination.totalPages-2) * this.pagination.size;
            // copy rows
            const _rows = [...this.campaignNumbers];
            // insert rows into new position
            _rows.splice(_start, page.body.length, ...page.body);
            // set rows to our new rows
            this.campaignNumbers = _rows.map(obj=>{
              obj.audioSrc = (obj.recording && obj.recording.url)? [{src: obj.recording.url,type:'audio/mp3'} ] : null
              return obj;
            });
            this.pageCache[this.pagination.totalPages- 2] = true;
            this.isLoading--;
            this.ref.markForCheck();
          })
          // .catch((error) => {
          //   console.error(error);
          // });
        }
        //////////////////////
        this.reset = true;
        this.isLoading--;
        console.log("setPage this.numbers :", this.campaignNumbers ,"isLoading",this.isLoading);
        this.ref.markForCheck();
      })
      // .catch((error) => {
      //   console.error(error);
      // });
  }
  periodToTime(period){
    let time:any={before:"",after:""};
    switch (period) {
      case 'today':
        time.before = _moment().startOf('day');
      time.after = _moment().endOf('day');
        break;
      case 'week':
        time.before = _moment()/*.isoWeekday(1)*/.startOf('week');
        time.after = _moment()/*.isoWeekday(1)*/.endOf('week');
        break;
      case 'month':
        time.before = _moment()/*.isoWeekday(1)*/.startOf('month');
        time.after = _moment()/*.isoWeekday(1)*/.endOf('month');
        break;
      case 'year':
        time.before = _moment()/*.isoWeekday(1).*/.startOf('year');
        time.after = _moment()/*.isoWeekday(1)*/.endOf('year');
        break;    
      default:
        break;
    }
    return time.before.unix()+"-"+time.after.unix();
  }
  filterByPeriod(event){
      this.default_filters = {search:{"created_at":  this.periodToTime(event)}};
      if(!this.filters) this.filters = {search:{}};
      if(!this.filters.search) this.filters.search = {};
      this.filters.search["created_at"] = this.periodToTime(event);
      this.resetTable(this.filters);
  }
  ngDoCheck(): void {
    // console.log("ngDoCheck", this.pagination.size ,this.table.pageSize )
    if(this.pagination.size && this.table.pageSize && this.pagination.size!=this.table.pageSize) {
      // console.warn("ngDoCheck start", this.pagination.size, this.table.pageSize,this.table)
      this.pageCache = {}//clear cache
      this.pageCallback({ count: this.table.rowCount, pageSize: this.table.pageSize, limit: this.table.pageSize, offset: 0 })
    }
  }
  // columnEvent(event,params){
  //   // @params - { index }
  //   this.columnOptions[params.index].$$id = undefined
  //   this.columnOptions = [...this.columnOptions]
  // }
  // getColumns(){
  //   // console.log("get col", this.columnOptions.filter((col)=>{return col.selected==true}))
  //   return this.columnOptions.filter((col)=>{return col.selected==true})
  // }
  export(){
    //let params = [{field:"call.created_at", operator:this.period}]
    this.campaignSvc.export(this.uuid, {export:'csv'/*, params:params*/}).subscribe((res)=> {
      if(res.headers && res.headers.get('x-report')) {
        var hiddenElement = document.createElement('a');
					    hiddenElement.href = res.headers.get('x-report');
					    hiddenElement.target = '_self';
					    hiddenElement.download = 'report.csv';
					    hiddenElement.click();
      }
      // this.loadingIndicator = false;
      // this.ref.detectChanges();
    });
  }
  downloadRecord(url:string,uuid:string){
    var hiddenElement = document.createElement('a');
    hiddenElement.href = url;
    hiddenElement.target = '_self';
    hiddenElement.download = 'campaignNumber__'+uuid+'.mp3';
    hiddenElement.click();
  }
  getPeriodToDisplay(){
    let res = "";
    switch (this.period) {
      case 'today':
        res = 'PERIOD.TODAY'
        break;
      case 'week':
        res = 'PERIOD.WEEK'
        break;
      case 'month':
        res = 'PERIOD.MONTH'
        break;
      case 'year':
        res = 'PERIOD.YEAR'
        break;    
      default:
        break;
    }
    return res;
  }
  applyBatch(actionParams) {
    console.log(actionParams);
    console.log(this.selected);

    let uuids:any;
    if(this._batchAction == "uuids") {
      // uuids = this.selected.map(e=>e.uuid);
      uuids = this.selected.map(e=>{
                              if(e)return e.uuid;//TODO
                            });
    }
    let search = this._batchAction == "search" ? this._savedQuery : undefined;
    console.log(uuids);
    console.log("batch", search);

    this.campaignSvc.batch(this._batchAction,this.uuid,actionParams,uuids,search).then(
      (res)=>{
        console.log(res);
        this.resetTable();
      }
    );
  }
  delete() {
    this.applyBatch({
      delete:true
    });
  }
  resetNumbers() {
    this.applyBatch({
      reset:true
    });
  }
  dial(uuid,obj){
    this.campaignSvc.DialNumber(uuid).then((res)=>{
      let msg="'Dialing '"
      if(obj.number && obj.number.number) {msg = "to "+obj.number.number }
      this.msgSvc.show(msg);
    })
    .catch((err)=>{console.log("err",err)});
  }
  onSelect({ selected }) {
    // console.log('Select Event', selected, this.selected, this.totalCount,this.isTotalSelected, this.table);
    if(this.isTotalSelected){
      // console.warn('Select Event', selected, this.selected, this.totalCount,this.isTotalSelected, this.table._offset, this.table);
      const start = this.table._offset * this.table.pageSize;
      this.selected = selected.splice(start, this.table.pageSize-1);
    }else{
      // this.selected = selected;
      this.selected = selected.filter((number) => {
        // console.log("number onSelect",number)
        if(number)return true
        else return false
      });
    }
    if(this.selected.length==this.totalCount) {this.isTotalSelected = true;}
    else {this.isTotalSelected = false;}
    this._batchAction = this.isTotalSelected ? this.isFiltered ? "search" : "all" : "uuids";
    this.ref.detectChanges()
  }
  selectAll(event) {
    console.log("select all ", event, this.isTotalSelected, this.selected)
    if (!this.isTotalSelected) {
      // Unselect all so we dont get duplicates.
      if (this.selected.length > 0) {
        this.campaignNumbers.map(number => {
          this.selected = this.selected.filter((selected) => {
            if(selected && number)return selected.uuid !== number.uuid
            else return false
          });
        })
      }
      // Select all again
      this.selected.push(...this.campaignNumbers);
      this.campaignNumbers = [...this.campaignNumbers];
      this.isTotalSelected = true;
      console.log("select all 22222", event, this.isTotalSelected, this.selected)
    } else {
      // Unselect all
      this.campaignNumbers.map(number => {
        this.selected = this.selected.filter((selected) => {
          if(selected && number)return selected.uuid !== number.uuid
          else return false
        });
      });
      this.isTotalSelected = false;
      console.log("select all 33333333333", event, this.isTotalSelected, this.selected)
    }
    this._batchAction = this.isTotalSelected ? this.isFiltered ? "search" : "all" : "uuids";
    this.ref.detectChanges()
 }
 filterByGroup(group_uuid, group){
  console.log("filter group")
  let data:any = {}
  data.params = {action:"filter", match:"all",search:{number_group:{"CONTAINS":[[group_uuid]]}}}
  data._segments = []
  for(let i=0; i<this._filterSelectField.length; i++){
    if(this._filterSelectField[i].name == 'number_group'){
      data._segments.unshift(this._filterSelectField[i])
      data._segments[0]._raw_value = [group]//TODO
      data._segments[0].field = "number_group"
      data._segments[0].condition = 'CONTAINS'
      data._segments[0]._type = data._segments[0].type;
      data._segments[0].value = '';
      data._segments[0].selected = data._segments[0].name;
      data._segments[0]._placeholder = data._segments[0]['placeholder'] || '';
      if(data._segments[0].data_url) {
        this.campaignSvc.getDataUrl(data._segments[0].data_url).then(
          res => {
            console.log(res);
            data._segments[0]._data = res;
          }
        );
      } else {
        data._segments[0]._data = data._segments[0]['data'] || [];
      }
    }
  }
  this._savedSegments = data._segments;
  this._savedQuery = data.params.search;
  this.campaignNumbers = null;
  this.pageCache = {};
  this.pagination.pageNumber = 0;
  data.params.per_page = this.pagination.size;
  this.isLoading=1;
  this.campaignSvc.filterNumbers(this.uuid,this.pagination.pageNumber+1, data.params)
  .then(res => {
    let total: number = Number.parseInt(res.headers.get('x-total'));
    this.pagination.totalPages = Math.ceil(total / this.pagination.size);
    this.pagination.totalElements = total;
    this.totalCount = total;
    // create array to store data if missing
    // length should be total count
    this.campaignNumbers = new Array<TyCampaignNumber>(this.totalCount || 0)

    const rows = [...this.campaignNumbers];
    // insert rows into new position
    rows.splice(0, res.json().length, ...res.json());
    // set rows to our new rows
    this.campaignNumbers = rows
    this.isFiltered = true;
    this.isLoading--;
    this.table.bodyComponent.updateOffsetY(0)//this.table.offset = 0;
    this._batchAction = "search";
  })
  .catch((error) => {
    console.error(error);
  });;
}
}
