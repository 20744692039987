// Angular
import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Injectable()
export class SplashScreenService {
	// Private properties
	private el: ElementRef[]=[];
	private stopped: boolean;

	/**
	 * Service constctuctor
	 *
	 * @param animationBuilder: AnimationBuilder
	 */
	constructor(private animationBuilder: AnimationBuilder) {
	}

	/**
	 * Init
	 *
	 * @param element: ElementRef
	 */
	init(element: ElementRef) {
		// this.el = element;
		this.el.push(element)
	}

	/**
	 * Hide
	 */
	hide() {
		if (this.stopped) {
			return;
		}
		let e = this.el.pop();//this.el;
		if(!e){
			return;
		}
		const player = this.animationBuilder.build([
			style({opacity: '1'}),
			animate(800, style({opacity: '0'}))
		]).create(e.nativeElement);
		
		player.onDone(() => {
			
			if (typeof e.nativeElement.remove === 'function') {
				e.nativeElement.remove();
			} else {
				e.nativeElement.style.display = 'none';
			}
			this.stopped = true;
		});

		setTimeout(() => player.play(), 300);
		console.log("ng ",this.el )
	}
}
