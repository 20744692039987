import { Component, Inject, TemplateRef, ViewChild } from "@angular/core";
import { MatDialogRef, MatMenuTrigger, MAT_DIALOG_DATA } from "@angular/material";
// import { CallService } from "../../../../../core/_base/layout";

@Component({
    selector: 'table-dialog',
    templateUrl: 'table-dialog.component.html',
  })
  export class TableDialog {
    @ViewChild('selecetdHeaderTmp') public selecetdHeaderTmp: TemplateRef<any>;
    @ViewChild('numberTmp') public numberTmp: TemplateRef<any>;
    @ViewChild('rowTmp') public rowTmp: TemplateRef<any>;
    @ViewChild('linkRowTmp') public linkRowTmp: TemplateRef<any>;
    @ViewChild('rowDateTmp') public rowDateTmp: TemplateRef<any>;
    @ViewChild('subRowTmp') public subRowTmp: TemplateRef<any>;
    @ViewChild('tooltipRowTmp') public tooltipRowTmp: TemplateRef<any>;
    @ViewChild('table') table;
    rows:any = [];
    columns:any = []
    columnOptions:any = []
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TableDialog>, 
    // private callSvc:CallService
    ) {
        console.log("TABLE DIALOG data", data)
        // this.callSvc.getPage(1).subscribe(res=>{

        // })
    }
    ngOnInit() {
        // this.callSvc.getColumns().subscribe((res)=>{
        //   this.columns = res;
        //   this.columnOptions = res;
        //   for(let i=0; i<this.columnOptions.length;i++){
        //     switch (this.columnOptions[i].type) {
        //       case "date":
        //         this.columnOptions[i].cellTemplate = this.rowDateTmp;
        //         break;
        //       case "link":
        //         this.columnOptions[i].cellTemplate = this.linkRowTmp;
        //         break;
        //       case "sub_object":
        //         this.columnOptions[i].cellTemplate = this.subRowTmp;
        //         break;
        //       case 'tooltip_object':
        //         this.columnOptions[i].cellTemplate = this.tooltipRowTmp;
        //         break;
        //       case 'sub_object_link':
        //         this.columnOptions[i].cellTemplate = this.subRowTmp;
        //         break;
        //       case 'string':
        //         this.columnOptions[i].cellTemplate = this.rowTmp;
        //         break;
        //       default:
        //         break;
        //     }
        //   }
        // })
        
    
    
        // this.router.navigate(['/:id', this.calls[0]._id]);
    }
    /**
   * Called whenever the user changes page
   *
   * check: https://swimlane.gitbooks.io/ngx-datatable/content/api/table/outputs.html
   */
//   pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
//     console.log("setPage test", pageInfo, this.pagination,  this.pageCache,this.pageCache[pageInfo.offset]);
    
//     this.pagination.pageNumber = pageInfo.offset;
//     this.pagination.size = pageInfo.pageSize;

//     // cache results
//     if (this.pageCache[pageInfo.offset]) {
//       return;
//     } else {
//       this.pageCache[pageInfo.offset] = true;
//     }
//     this.isLoading++;
//     console.warn("setPage test load page:", this.isLoading);
//     let filters:any = this.filters//{};
//     if(this.pagination.order_by) filters['order_by'] = this.pagination.order_by;
//     if(this.pagination.order_by) filters['order_type'] = this.pagination.order_type;
//     filters['per_page'] = this.pagination.size;
//     // this.ref.markForCheck();
//     this.callsSvc.getPage( pageInfo.offset + 1, filters)
//       .subscribe((page) => {
//         console.log("setPage this.numbers :", page);
//         let total: number = Number.parseInt(page.headers.get('x-total'));
//         this.pagination.totalElements = total;
//         this.totalCount = total;
//         this.pagination.totalPages = Math.ceil(total / this.pagination.size);

//         // create array to store data if missing
//         if (!this.calls) {
//           // length should be total count
//           this.calls = new Array<TyCall>(this.totalCount || 0);
//         }
//         console.log("setPage this.numbers :", this.calls ,"total",this.totalCount, this.calls.length);
//         // calc start
//         const start = pageInfo.offset * this.pagination.size;
//         console.log("setPage start:",start,"total:" ,this.totalCount,"setPage pageInfo.offset :", pageInfo.offset ,"setPage this.pagination.size:",this.pagination.size);
//         // copy rows
//         const rows = [...this.calls];
//         // insert rows into new position
//         rows.splice(start, page.body.length, ...page.body);
//         // set rows to our new rows
//         this.calls = rows.map(obj=>{
//           obj.audioSrc = (obj.recording && obj.recording.url)? [{src: obj.recording.url,type:'audio/mp3'} ] : null
//           return obj;
//         });
//         //////////////////////
//         //if loading last page --> load also one page before the last
//         if(pageInfo.offset!=0 && this.pagination.totalPages && pageInfo.offset==this.pagination.totalPages-1 && !this.pageCache[this.pagination.totalPages-2]){
//           let _filters:any = {};
//           this.isLoading++;
//           if(this.pagination.order_by) _filters['order_by'] = this.pagination.order_by;
//           if(this.pagination.order_by) _filters['order_type'] = this.pagination.order_type;
//           _filters.per_page = this.pagination.size;
//           this.callsSvc.getPage(this.pagination.totalPages- 1, _filters)
//           .subscribe((page:any) => {
//             // calc start
//             const _start = (this.pagination.totalPages-2) * this.pagination.size;
//             // copy rows
//             const _rows = [...this.calls];
//             // insert rows into new position
//             _rows.splice(_start, page.body.length, ...page.body);
//             // set rows to our new rows
//             this.calls = _rows.map(obj=>{
//               obj.audioSrc = (obj.recording && obj.recording.url)? [{src: obj.recording.url,type:'audio/mp3'} ] : null
//               return obj;
//             });
//             this.pageCache[this.pagination.totalPages- 2] = true;
//             this.isLoading--;
//             this.ref.markForCheck();
//           })
//           // .catch((error) => {
//           //   console.error(error);
//           // });
//         }
//         //////////////////////
//         this.reset = true;
//         this.isLoading--;
//         console.log("setPage this.numbers :", this.calls ,"isLoading",this.isLoading);
//         this.ref.markForCheck();
//       })
//       // .catch((error) => {
//       //   console.error(error);
//       // });
//   }
    columnEvent(event,params){
        // @params - { index }
        this.columnOptions[params.index].$$id = undefined
        this.columnOptions = [...this.columnOptions]
    }
    getColumns(){
        // console.log("get col", this.columnOptions.filter((col)=>{return col.selected==true}))
        return this.columnOptions.filter((col)=>{return col.selected==true})
    }
    loadAudio(row){
        row.showAudio = true;
    }
    audioMenuOpened:MatMenuTrigger ;
    audioOpened(trigger:MatMenuTrigger){
        if(this.audioMenuOpened) this.audioMenuOpened.closeMenu();
        this.audioMenuOpened = trigger
    }
    close(){
        this.dialogRef.close();
    }
    send(value){
        this.dialogRef.close(value);
    }
  }