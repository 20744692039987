// Angular
import { Injectable } from '@angular/core';
import { ActionCableService, Cable, Channel } from 'angular2-actioncable';
import { Subject } from 'rxjs';


declare var CONFIG:any;
@Injectable({
	providedIn: 'root'
})
export class WebsocketService {
	
    private subject: Subject<MessageEvent>;
    private ws: WebSocket;
    private _channelMap:{[key:string]:Channel} = {};
    private _cable: Cable;
    constructor(
      private ac: ActionCableService) {
    }
  
    public connect() {
      console.log("[connected to cabel]");
      this._cable = this.ac.cable(CONFIG.WEBSOCKETS_URL);
    }
  
    public getChannel(channel):Channel {
      return this._channelMap[channel];
    }
  
    public join(channel,params?:{}) {
      console.log("joined channel: "+channel);
      this._channelMap[channel] = this._cable.channel(channel,params);
    }
  
    public close() {
      console.log("[disconnected from cabel]");
      if(this._cable) this._cable.disconnect();
    }
  
    public leave(channel) {
      console.log("left channel: "+channel);
      if(this._channelMap && this._channelMap[channel]) {
        this._channelMap[channel].unsubscribe();
      }
    }
  
}
