import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  DriverService,
  SearchService
} from '../../../core/_base/layout';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  TyDriver
} from '../../../core/_base/layout/models/ty-driver.model';

@Component({
  selector: 'kt-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit, OnDestroy {
  drivers: TyDriver[];
  _driversOriginal: TyDriver[];

  constructor(private driverSvc: DriverService, 
              private search: SearchService, 
              private ref: ChangeDetectorRef) {
                
    this.search.focus.next('drivers');

    this.driverSvc.getAll().subscribe((drivers) => {
      this.drivers = this._driversOriginal = drivers;
    });

    this.search.driverHits.subscribe((hits) => {
      if(hits != null) {
        if(hits.length > 0) {
          this.drivers = hits;
        } else {
          this.drivers = []; 
        }
      } else {
        this.drivers = this._driversOriginal;
      }
      this.ref.detectChanges();
    });
  }
  ngOnDestroy() {
    
  }
  ngOnInit() {



    // this.router.navigate(['/:id', this.drivers[0]._id]);
  }

}
