import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { HandleRequest, LayoutConfigService, SplashScreenService } from './core/_base/layout';
// Layout
import { TranslationService } from './core/_base/layout';
// language list
// import { locale as enLang } from './core/_config/i18n/en';
// import { locale as chLang } from './core/_config/i18n/ch';
// import { locale as esLang } from './core/_config/i18n/es';
// import { locale as jpLang } from './core/_config/i18n/jp';
// import { locale as deLang } from './core/_config/i18n/de';
// import { locale as frLang } from './core/_config/i18n/fr';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Metronic';
	loader: boolean;
	favIcon: HTMLLinkElement = document.querySelector('#appIcon');
	private heLang = require('../assets/_config/i18n/he.json');
	private enLang = require('../assets/_config/i18n/en.json');
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private router: Router,
				private layoutConfigService: LayoutConfigService,private handleRequest:HandleRequest,
				private translationService: TranslationService,
				private splashScreenService: SplashScreenService)
	{
		this.translationService.loadTranslations(this.enLang, this.heLang);

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);
			}
		});
		this.unsubscribe.push(routerSubscription);

		var customer_data = this.handleRequest.getCustomerData()
		// this.customer_data = this.handleRequest.getCustomerData();
		if(customer_data.logo_color){
			var isOk = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(customer_data.logo_color);
			if(!isOk) customer_data.logo_color = '#03adad';
			document.documentElement.style.setProperty('--color-toyota-red', customer_data.logo_color);
		}
		this.favIcon.href = customer_data.favicon || customer_data.logo_icon
		document.title = customer_data.name + ' | Dashboard'
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
