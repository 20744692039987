import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  QueryList,
  ElementRef,
  ViewChildren
} from '@angular/core';
import {
  DashboardService,
  Events,
  SearchService
} from '../../../core/_base/layout';
import {
  Router,
  ActivatedRoute,
  Route
} from '@angular/router';
import { EnvironmentService } from '../../../core/_base/layout/services/environmet.service';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'kt-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
  providers:[DashboardService]
})
export class DashboardsComponent implements OnInit, OnDestroy {
  dashboards: any[];
  dashboards$:Observable<any[]>;
  searchQuery:string=''
  _dashboardsOriginal: [];
  show_menu:boolean=false
  dataAvaliable:boolean=false;;
  @ViewChildren('dashboardsElements') private dashboardsElements: QueryList<ElementRef>;
  constructor(private router:Router, private dashboardSvc: DashboardService, private activeRoute: ActivatedRoute,
              private search: SearchService, private events:Events,
              private ref: ChangeDetectorRef) {
                
    // this.search.focus.next('dashboards');
    this.loadDashboards();
    this.events.publish("screen-changed",{name:"dashboards", toggle:true})
    this.events.subscribe("toggle-menu", (data)=>{
      this.show_menu = data
    })
    // this.search.reportHits.subscribe((hits) => {
    //   if(hits != null) {
    //     if(hits.length > 0) {
    //       this.dashboards = hits;
    //     } else {
    //       this.dashboards = []; 
    //     }
    //   } else {
    //     this.dashboards = this._dashboardsOriginal;
    //   }
    //   this.ref.detectChanges();
    // });
  }
  searshDashboards(query){
    this.dashboards$ = of(this.dashboards).pipe(
      // delay(2000),
      map(c => {
        // if filter is empty return all dashboards
        if (query=='') {
          return c;
        }
    
        // search for specific dashboards_
        const filteredDashboards: any[] = [];
    
        c.filter(function(d) {
          if (d.name.toLowerCase().includes(query.toLowerCase())) {
            filteredDashboards.push(d);
          }d
        });
    
        return filteredDashboards;
      })
    );
  }
  loadDashboards(options:{reloadChild?:boolean} = {reloadChild:false}){
    this.dashboardSvc.getDashboards().then((dashboards) => {
      this.dashboards = this._dashboardsOriginal = dashboards;
      this.dashboards$ = of(this.dashboards)
      this.dataAvaliable = true;
      this.ref.detectChanges();
      if(this.dashboards && this.dashboards.length>0){
        let url = this.activeRoute.snapshot['_routerState'].url
        let urlArray = url.split("/")
        if(urlArray.length<5 || options.reloadChild){
          ((this.dashboardsElements.toArray())[0].nativeElement as HTMLElement).click()
        }
      }
      
    });
  }
  closeMenu(){
    this.events.publish("toggle-menu", false)
    this.show_menu=false;
  }
  ngOnDestroy() {
    this.events.destroy("toggle-menu")
  }
  ngOnInit() {
  }

}
