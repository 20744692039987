import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MessageComponent} from '../../../../views/partials/layout/message/message.component';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(public snackBar: MatSnackBar, private translateSvc:TranslationService) {}

  show(message: string, type='success', duration?: any) {
    // type= success/error/info
    this.snackBar.openFromComponent(MessageComponent, { 
      data: {type: type, message: message},
      panelClass: ["snackbar-container",type] ,
      duration: duration ? duration : 3000,
      horizontalPosition: (this.translateSvc.isRTL())? 'left':'right'
    });
  }
}
