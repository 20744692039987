import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService, SearchService } from '../../../../core/_base/layout';
import { TyReport } from '../../../../core/_base/layout/models/ty-report.model';
import { CirclePaint, GeoJSONSource, Source, GeoJSONSourceRaw, SymbolLayout } from 'mapbox-gl';
import { TranslationService } from '../../../../core/_base/layout/services/translation.service';
import { SplashScreenService } from '../../../../core/_base/layout';
import { MessageService } from '../../../../core/_base/layout';
import _moment from "moment";
import { DatePipe } from '@angular/common';
import { MatMenuTrigger } from '@angular/material';
import { EnvironmentService } from '../../../../core/_base/layout/services/environmet.service';
import { catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as shape from 'd3-shape';

const metersToPixelsAtMaxZoom = (meters, latitude) =>
  meters / 0.075 / Math.cos(latitude * Math.PI / 180);
@Component({
  selector: 'kt-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @ViewChild('actionsMenuTrigger') actionsMenuTrigger: MatMenuTrigger;
  show_report:boolean = false;
  report:any;//TODO TyReportObject
  reportObj: TyReport;
  activeTab;
  showFilter:boolean = false;
  paint: CirclePaint = {
    'circle-radius': 18,
    'circle-color': '#da0d0d',
    'circle-stroke-width':2,
    'circle-stroke-color':'#ffffff'
  };

  layout: SymbolLayout = {
    'text-field': '{title}',
    'text-font': [
      'DIN Offc Pro Medium',
      'Arial Unicode MS Bold'
    ],
    'text-size': 12
  };

  source:GeoJSONSourceRaw;
  mapCenter;
  mapZoom = [9];

  objectKeys = Object.keys;

  // datatable
  loadingIndicator: boolean = false;
  reloadPage: boolean = false;
  reorderable: boolean = false;
  period:string="";
  currentState:string="";
  write = false;

  // options
  showXAxis = true;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = false;
  tooltipDisabled = false;
  showText = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = '10%';
  barPadding = 6;
  groupPadding = 20;
  roundDomains = false;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  animations: boolean = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel = true;
  noBarWhenZero = true;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;
  linearCurve= shape.curveMonotoneX;
  colorScheme = {// see https://github.com/swimlane/ngx-charts/blob/master/projects/swimlane/ngx-charts/src/lib/utils/color-sets.ts
    natural:{
      name: 'natural',
      selectable: true,
      group: 'Ordinal',
      domain: [
        '#da0d0d','#dddddd','#2ba02d', '#f88323', '#a5d7c6', '#f2dfa7', '#7794b1','#d89f59',  '#afafaf', '#707160', '#ba9383', '#d9d5c3'
      ]
  },
  cool:{
    name: 'cool',
    selectable: true,
    group: 'Ordinal',
    domain: [
      '#a8385d',
      '#7aa3e5',
      '#a27ea8',
      '#aae3f5',
      '#adcded',
      '#a95963',
      '#8796c0',
      '#7ed3ed',
      '#50abcc',
      '#ad6886'
    ]
  }
};
  uuid:string;
  segments:any[]=[];
  params:any[]=[];
  private unsubscribe: Subscription[] = [];
  constructor(private search: SearchService,private msgSvc: MessageService, private envSvc:EnvironmentService, private router: Router, private route: ActivatedRoute,private ref: ChangeDetectorRef,  private reportSvc: ReportService, private translateSvc:TranslationService) {
    this.period = 'today';
    const envSubscription = this.envSvc.isChanched.subscribe(data=>{
      console.log("ngOn envSvc.isChanched", data)

      if(data && this.reportObj) {
        this.runReport();
      }
    })
    this.unsubscribe.push(envSubscription)
  }
  setSearch(){
    this.search.focus.next('report');

    console.log("searchhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
    this.reportSvc.getSegments(this.uuid)
      .then((res:any) => {
        this.segments = res;

        this.reportSvc.getParams(this.uuid)
        .then((res:any) => {
          console.log("this params ",this.params)
          this.params = [...res, ...this.params];
          this.search.init(this.segments,this.params)
          this.ref.detectChanges();
        });
    });
    const searchSubscription = this.search.filter.subscribe(data=>{
      console.log("this.search.filter", data)
      if(data) {
        let _filters:any;
        if(data.params){
          _filters= data.params
        }else{
          // this.params = [];
        }
        // this.filters = _filters;
        this.saveParams(data.params_to_save);
      }
    })
    this.unsubscribe.push(searchSubscription);
  }
  saveParams(params_to_save){
    this.reportSvc.saveParams( this.uuid,{params:params_to_save}).toPromise().then((res)=>{
      this.search.status.next('ok')
    },(err)=>{
      this.search.status.next('error')
    }).catch(err=>{console.error(err)})
  }
  ngOnDestroy(): void { 
    console.log("000000000000000000000000000000000000searchhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
  ngOnInit() {
    const routeQueryParamsSubscription = this.route.queryParams.subscribe(params => {
      console.log(params);

      this.activeTab = params.tab;
    });
    this.unsubscribe.push(routeQueryParamsSubscription);

    const routeParamMapSubscription = this.route.paramMap.subscribe(params => {
      console.log("ngOn paramMap", params)
      console.log("000000000000000000000000000000 routeQueryParamsSubscription")
      this.uuid = params.get('id');
      this.period = 'today';
      this.reportObj = null;
      this.show_report= false;
      this.reportSvc.getByUuid(this.uuid).subscribe((report)=> {
        if(report) {
          this.reportObj = report;
          // this.reportSvc.run(params.get('id')).subscribe((report)=> {
          //   if(report) {
          //     this.report = report;
          //     if(report.footer){
          //       report.footer.type = 'footer'
          //       report.footer[report.fields[0]] = 'Total'
          //       this.report.data.push(report.footer)
          //     }
          //   }
          //   this.show_report= true;
          //   this.ref.detectChanges();
          //   console.log("ngOninit", this.show_report)
          // });
          this.filterByPeriod(this.period)
          this.setSearch();
        }
      });
    });
    this.unsubscribe.push(routeQueryParamsSubscription)

  }
  reportComparator(valueA, valueB, rowA, rowB, sortDirection){
    // comparator: this.companyComparator.bind(this)
    console.warn('Sorting Comparator', valueA, valueB, rowA, rowB, sortDirection , rowA.type, rowB.type);
    let x:any;
    if(rowA.type=='footer'){
      if(sortDirection=='asc') x= 1;
      else x= -1;
    }else if(rowB.type=='footer'){
      if(sortDirection=='asc') x= 11;
      else x= 1;
    }else if(sortDirection=='asc'){
      x= (valueA < valueB)? -1 :1;
    }else{//sortDirection=='desc'
      x= (valueB < valueA)? -1:1;
    }
    console.log("Sorting Comparator res", x)
    return x;
  }
  dynamicSort(property, sort_order) {
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * ((sort_order=='desc')? 1 : -1);
    }
  }
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    // there will always be one "sort" object if "sortType" is set to "single"
    // this.pagination.order_type = sortInfo.sorts[0].dir;
    // this.pagination.order_by = sortInfo.sorts[0].prop;
    // this.resetTable();
    console.log("sortCallback", sortInfo.sorts[0].dir, sortInfo.sorts[0].prop, sortInfo)
    let footer = this.report.data.pop();
    this.report.data.sort(this.dynamicSort(sortInfo.sorts[0].prop,sortInfo.sorts[0]));
    this.report.data.push(footer);
    console.log("sortCallback", this.report.data)
    this.ref.markForCheck();
  }
  formatDate(x){
    const _datepipe:DatePipe =new DatePipe('en-US')
    return _datepipe.transform(x,'M/d/yy HH:mm') || ''
  }
  getLegend():any[] {
    let legend = [];
    let fields = [];
    if(this.report.data.length > 0) {
      if(this.report.type === 'pie' || this.report.type === 'line') {
        fields = this.report.data.map(v=>v.name);
      }
      if(this.report.type === 'bar') {
        // fields = this.report.data[0].series.map(v=>v.name);
        fields = this.report.data.map(v=>v.name);
      }
  
      for (let index = 0; index < fields.length; index++) {
        if(fields[index] !== '') {
          legend.push({
            title: fields[index],
            color: this.colorScheme.cool.domain[index]
          });
        }
      }
    }
    
    return legend;
  }
  export(){
    //let params = [{field:"call.created_at", operator:this.period}]
    this.reportSvc.export(this.reportObj.uuid, {export:'csv'/*, params:params*/}).subscribe((res)=> {
      if(res.headers && res.headers.get('x-report')) {
        var hiddenElement = document.createElement('a');
					    hiddenElement.href = res.headers.get('x-report');
					    hiddenElement.target = '_self';
					    hiddenElement.download = 'report.csv';
					    hiddenElement.click();
      }
      this.loadingIndicator = false;
      this.ref.detectChanges();
    });
  }
  filterByPeriod(event){
    this.loadingIndicator = true;
    let params = [{field:"call.created_at", operator:event}]
    this.show_report = false;
    this.reportSvc.saveParams(this.reportObj.uuid,{params:params}).subscribe((res)=> {
      if(res) {
        this.runReport();
      }
    });
  }
  runReport(){
    this.loadingIndicator = true;
    this.reloadPage = true;
    this.ref.detectChanges();
    this.reportSvc.run(this.reportObj.uuid).subscribe((res)=> {
      let report = res.body;
      if(report) {
        this.report = report;
        if(report.footer){
          report.footer.type = 'footer'
          report.footer[report.fields[0].field] = 'Total'
          this.report.data.push(report.footer)
        }
        if(report.row_meta){
          for (let key in report.row_meta) {
            // console.log(+key, report.row_meta[key]);
            this.report.data[+key].row_css = report.row_meta[key]
          }
        }
      }
      if(report.type=='bar'){
        // data: [{name, series:[{name,value}]}]
        let _data:any = []
        for(let i=0; i<report.data.length;i++){
          _data.push({name:report.data[i][0], value:report.data[i][1]})
        }
        report.data = _data
      }
      this.show_report= true;
      this.loadingIndicator = false;
      this.reloadPage = false;
      this.ref.detectChanges();
    },(err)=>{
      console.error(err)
      this.loadingIndicator = false;
      this.reloadPage = false;
    });
  }
  hashCode(str) { // java String#hashCode
    if(!str) return null;
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  } 
  intToRGB(i){
      if(!i) return '';
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - c.length) + c;
    }
  getRowStyle(rowIndex){
    if(rowIndex && this.report.row_meta[rowIndex] && this.report.row_meta[rowIndex].color){
      let row_css = this.report.row_meta[rowIndex].color
      return { 'background-color':row_css, 'color': '#'+this.intToRGB(this.hashCode(row_css))}//{ 'background-color': '#'+this.intToRGB(this.hashCode(color)), 'color': '#'+this.intToRGB(this.hashCode(color))}
    }else{
      return ''
    }
  }
  handleAction(action, row){
    console.log("handleAction", action);
    this.actionsMenuTrigger.closeMenu();
    if(action.type=='run_report'){
      // action = {
      //   "type": "run_report",
      //   "name": "All Calls",
      //   "icon": "icon",
      //   "report_uuid": "dc5006c6-017c-47fa-8617-94fc79b59c9b",
      //   "report_type": "pie",
      //   "report_name": "Test",
      //   "params": [
      //     {
      //       "id": "calls.today.count",
      //       "user_uuid": "{{user_uuid}}"
      //     }
      //   ]
      // },
      this.router.navigate(['en/app/reports/'+action.report_uuid, {params:action.params}]);
    }
    if(action.type=='http_request'){
      // action = {
      //   "type": "http_request",
      //   "name": "All Calls",
      //   "icon": "icon",
      //   "color": "red",
      //   "action": "post/patch/delete",
      //   "url": "...{{UUID}}...{{KEY1}}",
      //   "urlParams": {
      //      "UUID": "key in report row",
      //      "KEY1": "key in report row"
      //   },
      //   "params": [
      //     {
      //       "id": "calls.today.count",
      //       "user_uuid": "{{user_uuid}}"
      //     }
      //   ]
      // },
      let url = (' ' + action.url).slice(1);//force copy the url
      let keys:string[] = Object.keys(action.urlParams)
      for(let i=0; i<keys.length;i++){
        url = url.replace('{{'+keys[i]+'}}\g', row[action.urlParams[keys[i]]])
      }
      this.reportSvc.link(url, action.action, action.params).subscribe((res)=> {
        if(res) {
          this.msgSvc.show("Report action "+action.name+" completed successfully", 'success')
        }
      })

    }
  }
  getRowClass(row) {
    return {
      'footer': row.type == 'footer'
    };
  }

  getCellClass({ row, column, value }): any {
    return {
      'footer': row.type == 'footer'
    };
  }
  translationExists(key: string): boolean {
    return this.translateSvc.hasTranslation(key);
  }
  capitalize(s) {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}
